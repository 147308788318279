import {
	AUTH_START,
	AUTH_SUCCESS,
	AUTH_FAIL,
	AUTH_LOGOUT,
	AUTH_CHANGE_PASSWORD,
	SET_AUTH_REDIRECT_PATH,
	AUTH_RESET_PASSWORD,
} from "../actions/actionTypes";

import { updateObject } from "../../shared/utility";

const initialState = {
	user: null,
	role: null,
	token: null,
	error: null,
	msg: null,
	loading: false,
	authCheckLoading: true,
	authRedirectPath: "/",
};

const setAuthRedirectPath = (state, action) => {
	console.log(action.path);
	return updateObject(state, {
		authRedirectPath: action.path,
	});
};

const authStart = (state, action) => {
	return updateObject(state, {
		loading: true,
		error: null,
	});
};

const authSuccess = (state, action) => {
	return updateObject(state, {
		token: action.idToken,
		user: action.user,
		role: action.role,
		error: null,
		loading: false,
		authCheckLoading: false,
	});
};

const authLogout = (state, action) => {
	return updateObject(state, {
		token: null,
		user: null,
		role: null,
		authCheckLoading: false,
	});
};

const authChangePassword = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		authCheckLoading: false,
		msg: action.msg,
	});
};

const authFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
		authCheckLoading: false,
	});
};

const authResetPassword = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		authCheckLoading: false,
		msg: action.msg,
	});
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case AUTH_START:
			return authStart(state, action);
		case AUTH_SUCCESS:
			return authSuccess(state, action);
		case AUTH_FAIL:
			return authFail(state, action);
		case AUTH_LOGOUT:
			return authLogout(state, action);
		case AUTH_CHANGE_PASSWORD:
			return authChangePassword(state, action);
		case AUTH_RESET_PASSWORD:
			return authResetPassword(state, action);
		case SET_AUTH_REDIRECT_PATH:
			return setAuthRedirectPath(state, action);
		default:
			return state;
	}
};

export default authReducer;
