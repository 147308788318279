import axios from "axios";
import { displayLesson } from ".";

import {
	LESSONPLAN_ADD,
	LESSONPLAN_VIEW,
	LESSONPLAN_DELETE,
	LESSONPLAN_UPDATE,
	LESSONPLAN_START,
	LESSONPLAN_RESET,
	LESSONPLAN_FAIL,
	LESSONPLAN_UPLOAD,
	LESSONPLAN_UPLOAD_PLUSTWO,
} from "./actionTypes";

export const handleLessonPlanReset = () => {
	return {
		type: LESSONPLAN_RESET,
	};
};

export const LessonPlanCreate = (msg) => {
	return {
		type: LESSONPLAN_ADD,
		msg: msg,
	};
};

export const LessonPlanStart = () => {
	return {
		type: LESSONPLAN_START,
	};
};

export const LessonPlanFail = (msg) => {
	return {
		type: LESSONPLAN_FAIL,
		error: msg,
	};
};
export const LessonPlan = (LessonPlan) => {
	return {
		type: LESSONPLAN_VIEW,
		LessonPlan: LessonPlan,
	};
};

export const LessonPlanDestroy = (msg) => {
	return {
		type: LESSONPLAN_DELETE,
		msg: msg,
	};
};

export const LessonPlanPatch = (msg) => {
	return {
		type: LESSONPLAN_UPDATE,
		msg: msg,
	};
};

export const handleLessonPlanUpload = (msg) => {
	return {
		type: LESSONPLAN_UPLOAD,
		msg: msg,
	};
};

export const handleLessonPlanUploadPlusTwo = (msg) => {
	return {
		type: LESSONPLAN_UPLOAD_PLUSTWO,
		msg: msg,
	};
};

export const LessonPlanReset = () => {
	return (dispatch) => {
		dispatch(handleLessonPlanReset());
	};
};

export const LessonPlanAdd = (LessonPlan, startDate, endDate) => {
	console.log("Hello I'm from LessonPlanA.js");
	console.log(LessonPlan);

	return async (dispatch) => {
		// dispatch(LessonPlanStart());
		await axios
			.post("/lesson/store", {
				LessonPlan: LessonPlan,
				StartDate: startDate,
				EndDate: endDate,
			})
			.then((response) => {
				response.data.response.message
					? dispatch(LessonPlanCreate(response.data.response?.message))
					: dispatch(LessonPlanCreate("Network is not Established"));
			})
			.catch((err) => {
				console.log(err);
				err.data.message
					? dispatch(LessonPlanFail(err.data?.message))
					: dispatch(LessonPlanFail("Network is not Established"));
			});
		dispatch(LessonPlanView());
	};
};

export const LessonPlanDelete = (LessonPlan, subject_id, term_id, week) => {
	return async (dispatch) => {
		// dispatch(LessonPlanStart());
		await axios
			.post("lesson/" + LessonPlan, { _method: "DELETE" })
			.then((response) => {
				response.data.response.message
					? dispatch(LessonPlanDestroy(response.data.response?.message))
					: dispatch(
							LessonPlanDestroy("Something went wrong! Please try again.")
					  );
			})
			.catch((err) => {
				console.log(err);
				err.data.message
					? dispatch(LessonPlanFail(err.data?.message))
					: dispatch(LessonPlanFail("Network is not Established"));
			});
		dispatch(displayLesson(subject_id, term_id, week));
	};
};

export const LessonPlanUpdate = (id) => {
	return async (dispatch) => {
		// dispatch(LessonPlanStart());
		await axios
			.post("lesson/" + id, {
				_method: "PUT",
			})
			.then((response) => {
				response.data?.response?.message
					? dispatch(LessonPlanPatch(response.data?.response?.message))
					: response.data?.response
					? dispatch(LessonPlanPatch(response.data?.response))
					: dispatch(
							LessonPlanPatch("Something went wrong! Please try again.")
					  );
			})
			.catch((err) => {
				console.log(err);
				err.data?.message
					? dispatch(LessonPlanFail(err.data?.message))
					: err.data?.response
					? dispatch(LessonPlanFail(err.data?.response))
					: dispatch(LessonPlanFail("Network is not Established"));
			});
		dispatch(displayLesson());
	};
};

export const LessonPlanView = (id) => {
	return async (dispatch) => {
		dispatch(LessonPlanStart());
		console.log("from lesson Plan actions");
		console.log(id);
		await axios
			.get("lesson/details/" + id)
			.then((response) => {
				console.log(response);
				response.data.response
					? dispatch(LessonPlan(response.data.response))
					: dispatch(LessonPlanFail("Network is not Established"));
			})
			.catch((err) => {
				err.data?.message
					? dispatch(LessonPlanFail(err.data?.message))
					: dispatch(LessonPlanFail("Network is not Established"));
			});
	};
};

export const LessonPlanUpload = (file) => {
	return async (dispatch) => {
		dispatch(LessonPlanStart());
		await axios
			.post("/lessonPlan/import", { file: file })
			.then((response) => {
				response.data?.response
					? dispatch(handleLessonPlanUpload(response.data.response))
					: response.data?.response?.message
					? dispatch(handleLessonPlanUpload(response.data.response?.message))
					: dispatch(LessonPlanFail("Network is not Established"));
			})
			.catch((err) => {
				err?.data?.message
					? dispatch(LessonPlanFail(err?.data?.message))
					: err?.data?.response
					? dispatch(LessonPlanFail(err?.data?.response))
					: dispatch(LessonPlanFail("Network is not Established"));
			});
	};
};

export const LessonPlanUploadPlusTwo = (file) => {
	console.log("plus two", file);
	return async (dispatch) => {
		dispatch(LessonPlanStart());
		await axios
			.post("/lessonPlan/importPlusTwo", { file: file })
			.then((response) => {
				response.data.response
					? dispatch(handleLessonPlanUploadPlusTwo(response.data.response))
					: response.data.response?.message
					? dispatch(
							handleLessonPlanUploadPlusTwo(response.data.response?.message)
					  )
					: dispatch(LessonPlanFail("Network is not Established"));
			})
			.catch((err) => {
				err.data?.message
					? dispatch(LessonPlanFail(err.data?.message))
					: err.data?.response
					? dispatch(LessonPlanFail(err.data?.response))
					: dispatch(LessonPlanFail("Network is not Established"));
			});
	};
};
