import React from "react";
import {
	Navbar,
	Container,
	Row,
	Col,
	Image,
	Button,
	Nav,
} from "react-bootstrap";

import { isMobile } from "react-device-detect";

import classes from "../containers/App.module.css";
import navImg from "../assets/Images/sifalSchool.png";
import LoginPage from "../containers/Auth/loginpage";
import { Switch, Route, Link, Redirect } from "react-router-dom";
import MultipleCards from "./Card/MultipleCards";
import Grade from "../containers/view/grades/grades";
import Subject from "../containers/view/subject/subjects";
import LowerGradeCards from "./Card/LowerGradeCards";
import Logout from "../containers/Auth/logout";
import { connect } from "react-redux";
import { ProtectedRoute } from "../containers/ProtectedRoutes/protectedRoute";
import LessonPlan from "../containers/view/lessonPlan/lessonPlan";
import CrudGrade from "../containers/Auth/View/crudGrade";
import CrudSubject from "../containers/Auth/View/Subject/crudSubject";
import CrudTerm from "../containers/Auth/View/crudTerm";
import CrudLessonPlan from "../containers/Auth/View/crudLessonPlan";
import CrudUser from "../containers/Auth/View/User/crudUser";
import TeacherAssign from "../containers/Auth/View/User/teacherAssign";
import JuniorSchool from "../containers/Auth/View/LessonPlan/juniorSchool";
import HighSchool from "../containers/Auth/View/LessonPlan/highSchool";
import SubjectPriority from "../containers/Auth/View/Subject/subjectPriority";
import UpdatedSoon from "./updatedSoon";
import NotFound from "./notFound";
import HideSubject from "../containers/Auth/View/Subject/hideSubject";

import Dashboard from "../containers/Auth/View/dashboard";
import { TeacherRoute } from "../containers/ProtectedRoutes/teacherRoute";
import AdminNavComp from "./NavComp/AdminNavComp";
import TeacherNavComp from "./NavComp/TeacherNavComp";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
// const uploadLessonPlan = React.lazy(() => import("./Card/MultipleCards"));

const NavBar = (props) => {
	const style = {
		justifyContent: "flex-end",
		display: "flex",
		flex: 1,
	};

	return (
		<>
			<Navbar
				collapseOnSelect
				expand="sm"
				className={classes.NavColor}
				variant="light"
			>
				<Col>
					<Navbar.Brand>
						<Container>
							<Row>
								<Col>
									<Link to="/">
										<Image src={navImg} style={{ height: "100px" }} />
									</Link>
								</Col>
							</Row>
						</Container>
					</Navbar.Brand>
				</Col>

				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<div style={style}>
						{props.token === null ? (
							<Link to="/login" target="_blank">
								<Button variant="light" className={classes.loginbutton}>
									Login
									<FontAwesomeIcon
										icon={faSignOutAlt}
										style={{ margin: "0px 5px" }}
									/>
								</Button>
							</Link>
						) : (
							<>
								{isMobile ? (
									<Nav className="mr-auto">
										{props.isAdmin ? <AdminNavComp /> : <TeacherNavComp />}
									</Nav>
								) : props.isAdmin ? (
									<AdminNavComp />
								) : (
									<TeacherNavComp />
								)}
							</>
						)}
					</div>
				</Navbar.Collapse>
			</Navbar>

			<Switch>
				{/* <Route path="/" exact render={(props)=>null}/> */}
				<Route
					path="/"
					exact
					render={(props) => <MultipleCards {...props} />}
				/>
				<Route path="/login" render={(props) => <LoginPage {...props} />} />

				<Route
					path="/grade/:level"
					component={(props) => <Grade {...props} />}
				/>

				{/* <Route
					path="/lower"
					component={(props) => <LowerGradeCards {...props} />}
				/> */}

				<Route
					path="/subject/:grade"
					render={(props) => <Subject {...props} />}
				/>

				<Route path="/logout" render={(props) => <Logout {...props} />} />

				<TeacherRoute
					path="/dashboard"
					auth={props.isAuthenticated}
					component={(props) => <Dashboard {...props} />}
				/>
				<TeacherRoute
					path="/juniorSchool"
					auth={props.isAuthenticated}
					component={(props) => <JuniorSchool {...props} />}
				/>
				<TeacherRoute
					path="/highSchool"
					auth={props.isAuthenticated}
					component={(props) => <HighSchool {...props} />}
				/>
				<ProtectedRoute
					path="/Subject"
					auth={props.isAuthenticated}
					isAdmin={props.isAdmin}
					component={(props) => <CrudSubject {...props} />}
				/>
				<ProtectedRoute
					path="/Grade"
					auth={props.isAuthenticated}
					isAdmin={props.isAdmin}
					component={(props) => <CrudGrade {...props} />}
				/>
				<ProtectedRoute
					path="/Term"
					isAdmin={props.isAdmin}
					auth={props.isAuthenticated}
					component={(props) => <CrudTerm {...props} />}
				/>
				{/* <ProtectedRoute
          path="/crudLessonPlan"
          isAdmin={props.isAdmin}
          auth={props.isAuthenticated}
          component={(props) => <CrudLessonPlan {...props} />}
        /> */}
				<ProtectedRoute
					path="/Users"
					isAdmin={props.isAdmin}
					auth={props.isAuthenticated}
					component={(props) => <CrudUser {...props} />}
				/>
				<ProtectedRoute
					path="/teacherAssigned"
					isAdmin={props.isAdmin}
					auth={props.isAuthenticated}
					component={(props) => <TeacherAssign {...props} />}
				/>

				<ProtectedRoute
					path="/subjectPriority"
					isAdmin={props.isAdmin}
					auth={props.isAuthenticated}
					component={(props) => <SubjectPriority {...props} />}
				/>
				<ProtectedRoute
					path="/subjectHide"
					isAdmin={props.isAdmin}
					auth={props.isAuthenticated}
					component={(props) => <HideSubject {...props} />}
				/>

				<Route
					path="/ViewLessonPlan/:subject/:term/:week"
					component={(props) => <LessonPlan {...props} />}
				/>

				<Route
					path="/updated-soon"
					component={(props) => <UpdatedSoon {...props} />}
				/>

				{/* <Route
          path="lesson"
          render={() => (
            <Suspense fallback={<div>Loading...</div>}>
              <Lesson />
            </Suspense>
          )}
        /> */}

				<Route render={(props) => <NotFound />} />
			</Switch>
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		token: state.authR.token,
		isAuthenticated: state.authR.token !== null,
		isAdmin: state.authR?.role === "admin",
		msg: state.authR.msg,
	};
};

export default connect(mapStateToProps)(NavBar);
