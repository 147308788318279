import { Button, Modal } from "react-bootstrap";

const EditModal = (props) => {
  console.log(props.id);
  console.log("props.id");
  console.log(props.value);
  console.log("props.value");

  // const [propsVal, setpropsVal] = useState({ ...props.value });

  const clickHandler = () => {
    props.onEditRef(props.id, props.value);
    props.onHide();
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input
          type="text"
          className="form-control"
          value={props.value}
          onChange={(e) => {
            props.setValue({ show: true, id: props.id, value: e.target.value });
          }}
        />
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "flex-start" }}>
        <Button onClick={clickHandler} className="btn-info">
          Edit
        </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
