import React, { createContext, useEffect, useState } from "react";
import {
	Row,
	Container,
	Table,
	Pagination,
	Button,
	Col,
} from "react-bootstrap";
import Spinner from "../../../components/spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { removeTags } from "../../../shared/htmlTagRemover";
import {
	faDownload,
	faEdit,
	faTrash,
	faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import {
	BulkTeacherAssign,
	BulkTeacherDelete,
	dashboard,
	search,
	teacherDelete,
	teacherReset,
	teacherUpdate,
	teacherView,
} from "../../../store/actions";
import DeleteModal from "../../../components/Modal/DeleteModal";
import DashboardEditModal from "../../../components/Modal/Dashboard/DashboardEditModal";
import sample from "../../../assets/Sample/Bulk Teacher Assign Lesson Plan - Grade 2.csv";
import PasswordChange from "../changePassword";
import { Redirect } from "react-router";

const Dashboard = (props) => {
	const [file, setfile] = useState({
		selectedFile: null,
		selected: false,
		errorMsg: null,
	});

	const [data, setdata] = useState({
		user_id: null,
		subject_id: null,
		grade_id: null,
	});

	const [query, setquery] = useState("");

	const [DashboardModalEdit, setDashboardModalEdit] = useState({
		show: false,
		id: null,
	});

	const [DashboardModalDelete, setDashboardModalDelete] = useState({
		show: false,
		id: null,
	});

	const [modalDeleteShow, setDeleteModalShow] = useState(false);

	const editRef = props.onteacherEdit;
	const delRef = props.ondelete;
	const dashboardDelRef = props.onteacherDelete;

	useEffect(() => {
		return props.onDashboard(), props.onTeacherView(), props.onteacherReset();
	}, []);

	if (props.loading) {
		return <Spinner />;
	}

	if (props.teachererror) {
		toast(props.teachererror, {
			position: "top-center",
			type: "error",
			toastId: "custom-id",
		});
		console.log(props);
	}
	if (props.teachermsg) {
		toast(props.teachermsg, {
			position: "top-center",
			type: "success",
			toastId: "custom-id",
		});
	}
	if (props.authmsg) {
		toast(props.authmsg, {
			position: "top-center",
			type: "success",
			toastId: "custom-id",
		});
	}
	const onFileChange = (event) => {
		setfile({
			selectedFile: event.target.files[0],
			selected: true,
			errorMsg: null,
		});
	};

	const encodeFileBase64 = (file) => {
		return new Promise((resolve) => {
			let reader = new FileReader();
			if (file) {
				reader.readAsDataURL(file);
				reader.onload = () => {
					let Base64 = reader.result;
					resolve(Base64);
				};
				reader.onerror = (err) => {
					console.log("error", err);
				};
			}
		});
	};

	const BulkUploadHandler = async (e) => {
		if (file.selected) {
			const result = await encodeFileBase64(file.selectedFile);
			props.onBulkUserUpload(result);
			console.log(result);
		} else {
			setfile((prev) => {
				prev.errorMsg = " Please select file";
				return { ...prev };
			});
		}
	};
	const SearchHandler = (e) => {
		e.preventDefault();
		console.log(query);
		props.onSearch(query);
	};

	if (!props.isAuthenticated) {
		<Redirect to="/login" />;
	}

	let passwordChangeFlag = null;

	if (props.isAuthenticated) {
		passwordChangeFlag = JSON.parse(
			localStorage.getItem("user")
		).password_change_flag;
	}

	return (
		<>
			{passwordChangeFlag === "0" ? (
				<PasswordChange />
			) : (
				<>
					<Container>
						<Row>
							{props.isAdmin ? (
								<>
									{file.errorMsg
										? toast(file.errorMsg, { toastId: "custom-id" })
										: null}
									<Col md={12}>
										<div className="form-inline">
											<input
												id="file"
												type="file"
												className="form-control"
												name="file"
												required
												onChange={onFileChange}
												style={{ margin: "0px 5px" }}
												{...console.log(file)}
											/>

											<Button variant="success" onClick={BulkUploadHandler}>
												<FontAwesomeIcon
													icon={faUpload}
													style={{ marginRight: "5px" }}
												/>
												Bulk Upload
											</Button>
											<Col>
												<div>
													<a
														href={sample}
														download="SampleTeacherAssign.csv"
														className="btn btn-primary right"
													>
														<FontAwesomeIcon
															icon={faDownload}
															style={{ marginRight: "5px" }}
														/>
														Download Sample
													</a>
												</div>
											</Col>
											<Col style={{ paddingRight: "0px" }}>
												<Button
													variant="danger"
													onClick={() => setDeleteModalShow(true)}
													className="float-right"
												>
													<FontAwesomeIcon
														icon={faTrash}
														style={{ marginRight: "5px" }}
													/>
													Delete all assigned user
												</Button>
											</Col>
										</div>
									</Col>
									<Col style={{ marginTop: "10px" }}>
										<div className="input-group mb-3">
											<input
												type="search"
												className="form-control"
												placeholder="Enter a Keyword"
												aria-label="Enter a Keyword"
												aria-describedby="basic-addon2"
												onChange={(e) => {
													setquery(e.target.value);
												}}
												value={query}
											/>
											<div className="input-group-append">
												<Button variant="dark" onClick={SearchHandler}>
													Search
												</Button>
											</div>
										</div>
									</Col>
								</>
							) : null}

							<Table
								responsive
								striped
								bordered
								hover
								style={{ textAlign: "center", marginTop: "15px" }}
							>
								<thead>
									<tr>
										<th style={{ width: "15%" }}>S.N.</th>
										<th>First Name</th>
										<th>Last Name</th>
										<th>Email</th>
										<th>Subject</th>
										<th>Grade</th>
										{props.isAdmin ? <th>Action</th> : null}
									</tr>
								</thead>
								{props.dashboardData?.data?.length > 0 ? (
									<tbody>
										{[...props.dashboardData?.data].map((value, i) => {
											console.log(value);
											return (
												<tr key={value.id}>
													<td>{i + 1}</td>
													<td>{value.user.FirstName}</td>
													<td>{value.user.LastName}</td>
													<td>{value.user.email}</td>
													<td>
														{props.isAdmin ? (
															<p>{value.subject.Subject}</p>
														) : (
															<>
																<a href={"/subject/" + value.subject_id}>
																	{value.subject.Subject}
																</a>
															</>
														)}
													</td>
													<td>{value.grade.Grade}</td>
													{props.isAdmin ? (
														<td>
															<FontAwesomeIcon
																icon={faEdit}
																style={{ margin: "0px 5px" }}
																onClick={() => {
																	setDashboardModalEdit({
																		show: true,
																		id: value.id,
																	});
																	setdata({
																		user_id: value.user.id,
																		subject_id: value.subject.id,
																		grade_id: value.grade.id,
																	});
																}}
															/>

															<FontAwesomeIcon
																icon={faTrash}
																onClick={() =>
																	setDashboardModalDelete({
																		show: true,
																		id: value.id,
																	})
																}
															/>
														</td>
													) : null}
												</tr>
											);
										})}
									</tbody>
								) : (
									<tbody>
										<tr>
											<td colSpan="7" className="text-center">
												Subject not assigned to any teacher !!
											</td>
										</tr>
									</tbody>
								)}
							</Table>

							{props.dashboardData?.data?.length > 0 ? (
								<Pagination>
									<Pagination.First
										onClick={() =>
											props.onDashboard(props.dashboardData?.firstPageUrl)
										}
									/>
									{[...props.dashboardData?.link].map((value, i) => {
										return value.url === null ? null : (
											<Pagination.Item
												active={value.active}
												key={i.toString()}
												onClick={() => props.onDashboard(value?.url)}
											>
												{removeTags(value.label)}
											</Pagination.Item>
										);
									})}
									<Pagination.Last
										onClick={() =>
											props.onDashboard(props.dashboardData?.lastPageUrl)
										}
									/>
								</Pagination>
							) : null}

							{DashboardModalEdit.show ? (
								<DashboardEditModal
									show={DashboardModalEdit.show}
									id={DashboardModalEdit.id}
									state={data}
									setStateRef={setdata}
									data={props.teacher}
									onHide={() =>
										setDashboardModalEdit({
											show: false,
											id: null,
											value: null,
										})
									}
									onEditRef={editRef}
									fromDashboard={true}
								/>
							) : null}

							{DashboardModalDelete.show ? (
								<DeleteModal
									show={DashboardModalDelete.show}
									id={DashboardModalDelete.id}
									name="assigned teacher"
									onHide={() =>
										setDashboardModalDelete({ show: false, id: null })
									}
									onDelRef={dashboardDelRef}
								/>
							) : null}

							{modalDeleteShow ? (
								<DeleteModal
									show={modalDeleteShow}
									name="all assigned Teachers"
									onHide={() => setDeleteModalShow(false)}
									onDelRef={delRef}
								/>
							) : null}
						</Row>
					</Container>
					<ToastContainer />
				</>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		teacher: state.teacherR.teacher,
		isAdmin: state.authR?.role === "admin",
		teachermsg: state.teacherR.msg,
		teachererror: state.teacherR.error,
		loading: state.teacherR.loading,
		dashboardData: state.teacherR.dashboardData,
		isAuthenticated: state.authR.token !== null,
		authmsg: state.authR.msg,
		autherror: state.authR.error,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onteacherEdit: (id, user_id, subject_id, grade_id, dashboard) =>
			dispatch(teacherUpdate(id, user_id, subject_id, grade_id, dashboard)),

		onteacherDelete: (grade, id) => dispatch(teacherDelete(grade, id)),

		onDashboard: (url) => dispatch(dashboard(url)),

		ondelete: () => dispatch(BulkTeacherDelete()),

		onSearch: (query) => dispatch(search(query)),

		onteacherReset: () => dispatch(teacherReset()),

		onBulkUserUpload: (file) => dispatch(BulkTeacherAssign(file)),

		onTeacherView: () => dispatch(teacherView()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
