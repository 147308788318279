import { Button, Modal } from "react-bootstrap";

const ResetPasswordModal = (props) => {
	console.log(props.id);

	const clickHandler = (event) => {
		props.onPasswordResetRef(props.id);

		props.onHide();
	};
	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Reset Password
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>Are you sure you want to reset the password ?</p>
			</Modal.Body>
			<Modal.Footer style={{ justifyContent: "flex-start" }}>
				<Button onClick={clickHandler} className="btn-danger">
					Reset Password
				</Button>
				<Button onClick={props.onHide}>Close</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ResetPasswordModal;
