import { updateObject } from "../../shared/utility";

import {
  TEACHER_ASSIGN,
  TEACHER_DELETE,
  TEACHER_VIEW,
  TEACHER_UPDATE,
  TEACHER_FAIL,
  TEACHER_START,
  TEACHER_SHOW,
  TEACHER_RESET,
  BULK_TEACHER_ASSIGN,
  BULK_TEACHER_DELETE,
  DASHBOARD,
  HOME_SEARCH,
} from "../actions/actionTypes";

const initialState = {
  teacher: [],
  teacherShow: [],
  dashboardData: [],
  msg: null,
  error: null,
  loading: false,
};

const teacherReset = (state, action) => {
  return updateObject(state, {
    teacher: [],
    teacherShow: [],
    dashboardData: [],
    msg: null,
    error: null,
  });
};

const teacherAssign = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const teacherStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    // msg: null,
  });
};

const teacherFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    teacher: [],
    teacherShow: [],
    msg: null,
    error: action.error,
  });
};
const teacherView = (state, action) => {
  return updateObject(state, {
    loading: false,
    teacher: action.teacher,
  });
};

const teacherDelete = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const teacherUpdate = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const teacherShow = (state, action) => {
  return updateObject(state, {
    loading: false,
    teacherShow: action.teacher,
  });
};

const dashboard = (state, action) => {
  return updateObject(state, {
    loading: false,
    dashboardData: action.dashboardData,
    error: null,
  });
};

const search = (state, action) => {
  return updateObject(state, {
    loading: false,
    dashboardData: action.dashboardData,
    error: null,
  });
};

const bulkTeacherAssign = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const bulkTeacherDelete = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const teacherReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEACHER_RESET:
      return teacherReset(state, action);
    case TEACHER_START:
      return teacherStart(state, action);
    case TEACHER_ASSIGN:
      return teacherAssign(state, action);
    case TEACHER_VIEW:
      return teacherView(state, action);
    case TEACHER_DELETE:
      return teacherDelete(state, action);
    case TEACHER_UPDATE:
      return teacherUpdate(state, action);
    case TEACHER_FAIL:
      return teacherFail(state, action);
    case TEACHER_SHOW:
      return teacherShow(state, action);
    case DASHBOARD:
      return dashboard(state, action);
    case HOME_SEARCH:
      return search(state, action);
    case BULK_TEACHER_ASSIGN:
      return bulkTeacherAssign(state, action);
    case BULK_TEACHER_DELETE:
      return bulkTeacherDelete(state, action);
    default:
      return state;
  }
};

export default teacherReducer;
