import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";

import { faDownload, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sample from "../../../../assets/Sample/SampleLessonPlan.csv";
import { connect } from "react-redux";
import {
	LessonPlanReset,
	LessonPlanUploadPlusTwo,
} from "../../../../store/actions";
import Spinner from "../../../../components/spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const HighSchool = (props) => {
	const [file, setfile] = useState({
		selectedFile: null,
		selected: false,
		errorMsg: null,
	});

	const [loading, setLoading] = useState(true);

	const onFileChange = (event) => {
		setfile({
			selectedFile: event.target.files[0],
			selected: true,
			errorMsg: null,
		});
	};

	useEffect(() => {
		return (
			props.onLessonPlanReset(),
			setTimeout(() => {
				setLoading(false);
			}, 1500)
		);
	}, []);

	if (props.loading || loading) {
		return <Spinner />;
	}

	const encodeFileBase64 = (file) => {
		return new Promise((resolve) => {
			let reader = new FileReader();
			if (file) {
				reader.readAsDataURL(file);
				reader.onload = () => {
					let Base64 = reader.result;
					resolve(Base64);
				};
				reader.onerror = (err) => {
					console.log("error", err);
				};
			}
		});
	};

	const submitHandler = async (e) => {
		if (file.selected) {
			const result = await encodeFileBase64(file.selectedFile);
			props.onBulkUpload(result);
			console.log(result);
		} else {
			setfile((prev) => {
				prev.errorMsg = " Please select file";
				return { ...prev };
			});
		}
		// setfile((prev) => {
		//   return {
		//     selectedFile: prev.selectedFile,
		//     selected: prev.selected,
		//     errorMsg: "null",
		//   };
		// });
		console.log(file);
	};

	if (props.error) {
		toast(props.error, {
			position: "top-center",
			type: "error",
			toastId: "custom-id",
		});
	}
	if (props.msg) {
		toast(props.msg, {
			position: "top-center",
			type: "success",
			toastId: "custom-id",
		});
	}

	return (
		<>
			<Container>
				<h5 className="text-center">Upload Lessons 11-12</h5>
				{file.errorMsg
					? toast(file.errorMsg, {
							position: "top-center",
							type: "error",
							toastId: "custom-id",
					  })
					: null}
				<div className="float-right">
					<a
						href={sample}
						download="SampleLessonPlan.csv"
						className="btn btn-primary right"
					>
						<FontAwesomeIcon icon={faDownload} style={{ marginRight: "5px" }} />
						Download Sample
					</a>
				</div>
				<div className="form-inline">
					<input
						id="file"
						type="file"
						className="form-control"
						name="file"
						required
						onChange={onFileChange}
						style={{ margin: "0px 5px" }}
						{...console.log(file)}
					/>

					<Button variant="success" onClick={submitHandler}>
						<FontAwesomeIcon icon={faUpload} style={{ marginRight: "5px" }} />
						Bulk Upload
					</Button>
				</div>
			</Container>
			<ToastContainer />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		msg: state.lessonPlanR.msg,
		loading: state.lessonPlanR.loading,
		error: state.lessonPlanR.error,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onLessonPlanReset: () => dispatch(LessonPlanReset()),

		onBulkUpload: (file) => dispatch(LessonPlanUploadPlusTwo(file)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(HighSchool);
