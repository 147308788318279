import axios from "axios";

import {
	SUBJECT_CLASS_PRIORITY_START,
	SUBJECT_CLASS_PRIORITY_FAIL,
	SUBJECT_CLASS_PRIORITY_VIEW,
	SUBJECT_CLASS_PRIORITY_UPDATE,
	SUBJECT_CLASS_PRIORITY_DELETE,
	SUBJECT_CLASS_PRIORITY_GET_WEEK,
	SUBJECT_CLASS_PRIORITY_DISPLAY_LESSON,
	SUBJECT_CLASS_PRIORITY_HIDE,
	SUBJECT_CLASS_PRIORITY_RESET,
} from "./actionTypes";

export const handlesubjectClassPriorityStart = () => {
	return {
		type: SUBJECT_CLASS_PRIORITY_START,
	};
};

export const handlesubjectClassPriorityFail = (msg) => {
	return {
		type: SUBJECT_CLASS_PRIORITY_FAIL,
		error: msg,
	};
};

export const handlesubjectClassPriorityView = (subjectClassPriority) => {
	return {
		type: SUBJECT_CLASS_PRIORITY_VIEW,
		subjectClassPriority: subjectClassPriority,
	};
};

export const handlesubjectClassPriorityDestroy = (msg) => {
	return {
		type: SUBJECT_CLASS_PRIORITY_DELETE,
		msg: msg,
	};
};

export const handlesubjectClassPriorityUpdate = (msg) => {
	return {
		type: SUBJECT_CLASS_PRIORITY_UPDATE,
		msg: msg,
	};
};

export const handledisplayLesson = (lessonPlan, week) => {
	return {
		type: SUBJECT_CLASS_PRIORITY_DISPLAY_LESSON,
		lessonPlan: lessonPlan,
		week: week,
	};
};

export const handleGetWeek = (msg) => {
	return {
		type: SUBJECT_CLASS_PRIORITY_GET_WEEK,
		msg: msg,
	};
};

export const handleSubjectClassPriorityHide = (msg) => {
	return {
		type: SUBJECT_CLASS_PRIORITY_HIDE,
		msg: msg,
	};
};

export const handleResetState = () => {
	return {
		type: SUBJECT_CLASS_PRIORITY_RESET,
	};
};

export const SubjectClassResetState = () => {
	return (dispatch) => {
		dispatch(handleResetState());
	};
};

export const subjectClassPriorityView = (id) => {
	return async (dispatch) => {
		dispatch(handlesubjectClassPriorityStart());
		await axios
			.get("subjectClassPriority/details/" + id)
			.then((response) => {
				console.log(response.data.response);
				response.data?.response
					? dispatch(handlesubjectClassPriorityView(response.data?.response))
					: dispatch(
							handlesubjectClassPriorityFail("Network is not Established")
					  );
			})
			.catch((err) => {
				console.log(err);
				err?.data?.response
					? dispatch(handlesubjectClassPriorityFail(err?.data?.response))
					: dispatch(
							handlesubjectClassPriorityFail("Network is not Established")
					  );
			});
	};
};

export const subjectClassPriorityUpdate = (
	id,
	grade_id,
	subject_id,
	user_id,
	priority
) => {
	return async (dispatch) => {
		await axios
			.post("subjectClassPriority/" + id, {
				grade_id: grade_id,
				subject_id: subject_id,
				user_id: user_id,
				Priority: priority,
				_method: "PUT",
			})
			.then((response) => {
				response.data?.response?.message
					? dispatch(
							handlesubjectClassPriorityUpdate(response.data?.response?.message)
					  )
					: response.data?.response
					? dispatch(handlesubjectClassPriorityUpdate(response.data?.response))
					: dispatch(
							handlesubjectClassPriorityUpdate(
								"Something went wrong! Please try again."
							)
					  );
			})
			.catch((err) => {
				console.log(err);
				err.data?.message
					? dispatch(handlesubjectClassPriorityFail(err.data?.message))
					: err.data?.response
					? dispatch(handlesubjectClassPriorityFail(err.data?.response))
					: dispatch(
							handlesubjectClassPriorityFail("Network is not Established")
					  );
			});
		dispatch(subjectClassPriorityView(grade_id));
	};
};

export const subjectClassPriorityDelete = (id, subjectClassPriority) => {
	return async (dispatch) => {
		// dispatch(subjectStart());

		await axios
			.post("subjectClassPriority/" + subjectClassPriority, {
				_method: "DELETE",
			})
			.then((response) => {
				response.data?.response?.message
					? dispatch(
							handlesubjectClassPriorityDestroy(
								response.data?.response?.message
							)
					  )
					: response.data?.response
					? dispatch(handlesubjectClassPriorityDestroy(response.data?.response))
					: dispatch(
							handlesubjectClassPriorityDestroy(
								"Something went wrong! Please try again."
							)
					  );
			})
			.catch((err) => {
				console.log(err);
				err.data?.message
					? dispatch(handlesubjectClassPriorityFail(err.data?.message))
					: err.data?.response
					? dispatch(handlesubjectClassPriorityFail(err.data?.response))
					: dispatch(
							handlesubjectClassPriorityFail("Network is not Established")
					  );
			});
		dispatch(subjectClassPriorityView(id));
	};
};

export const getWeek = (date, grade_id, subject_id) => {
	return async (dispatch) => {
		dispatch(handlesubjectClassPriorityStart());
		await axios
			.post("week", {
				date: date,
				class: grade_id,
				subject: subject_id,
			})
			.then((response) => {
				console.log("response", response);
				response.data?.response === "Site will be updated Shortly"
					? dispatch(handleGetWeek(response.data?.response))
					: dispatch(
							displayLesson(
								response.data.response.id,
								response.data.response.term,
								response.data.response.week
							)
					  );
			})
			.catch((err) => {
				console.log("Error", err);
				err.data.message
					? dispatch(handlesubjectClassPriorityFail(err.data?.message))
					: dispatch(
							handlesubjectClassPriorityFail("Network is not Established")
					  );
			});
	};
};

export const displayLesson = (subject_id, term_id, week) => {
	return async (dispatch) => {
		dispatch(handlesubjectClassPriorityStart());
		await axios
			.get(`lesson/${subject_id}/${term_id}/${week}`)
			.then((response) => {
				response.data?.response
					? dispatch(handledisplayLesson(response.data?.response, week))
					: response.data?.response?.message
					? dispatch(
							handledisplayLesson(response.data?.response?.message, week)
					  )
					: dispatch(
							handlesubjectClassPriorityFail(
								"Something went wrong! Please try again."
							)
					  );
			})
			.catch((err) => {
				console.log(err);
				err.data?.message
					? dispatch(handlesubjectClassPriorityFail(err.data?.message))
					: err.data?.response
					? dispatch(handlesubjectClassPriorityFail(err.data?.response))
					: dispatch(
							handlesubjectClassPriorityFail("Network is not Established")
					  );
			});
	};
};

export const subjectClassPriorityHide = (id, hide, grade) => {
	return async (dispatch) => {
		await axios
			.post("subjectClassPriority/hide/" + id, {
				hide: hide,
				_method: "PUT",
			})
			.then((response) => {
				response.data?.response
					? dispatch(handleSubjectClassPriorityHide(response.data?.response))
					: response.data?.response?.message
					? dispatch(
							handleSubjectClassPriorityHide(response.data?.response?.message)
					  )
					: dispatch(
							handleSubjectClassPriorityHide(
								"Something went wrong! Please try again."
							)
					  );
			})
			.catch((err) => {
				console.log(err);
				err.data?.response?.message
					? dispatch(
							handlesubjectClassPriorityFail(err.data?.response?.message)
					  )
					: err.data?.message
					? dispatch(handlesubjectClassPriorityFail(err.data?.message))
					: err.data?.response
					? dispatch(handlesubjectClassPriorityFail(err.data?.response))
					: dispatch(
							handlesubjectClassPriorityFail("Network is not Established")
					  );
			});
		dispatch(subjectClassPriorityView(grade));
	};
};
