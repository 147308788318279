import React from "react";
import ViewCards from "./Viewcards";
import onetofour from "../../assets/Images/Grade 1 to Grade 4.jpg";
import fivetoeight from "../../assets/Images/Grade 5 to Grade 8.jpg";
import ninetwelve from "../../assets/Images/Grade 9 to Grade 12.jpg";
import { Container, Row } from "react-bootstrap";

const MultipleCards = (props) => {
	return (
		<Container fluid>
			<Row>
				{[...Array(3)].map((_, i) => (
					<ViewCards
						key={i.toString()}
						image={i == 0 ? onetofour : i == 1 ? fivetoeight : ninetwelve}
						url={i == 0 ? "lower" : i == 1 ? "middleSchool" : "highSchool"}
						number={4}
					/>
				))}
			</Row>
		</Container>
	);
};

export default MultipleCards;
