import React, { useEffect, useState } from "react";
import {
	Col,
	Container,
	Pagination,
	Row,
	Table,
	Button,
} from "react-bootstrap";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditModal from "../../../../components/Modal/EditModal";
import DeleteModal from "../../../../components/Modal/DeleteModal";
import { connect } from "react-redux";
import Spinner from "../../../../components/spinner/Spinner";
import {
	subjectAdd,
	subjectDelete,
	subjectView,
	subjectUpdate,
	subjectReset,
	subjectSearch,
} from "../../../../store/actions";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { removeTags } from "../../../../shared/htmlTagRemover";

const CrudSubject = (props) => {
	console.log(props, "CRUD Subject");
	const [subject, setsubject] = useState("");
	const [query, setquery] = useState("");

	const [modalEditShow, setEditModalShow] = useState({
		show: false,
		id: null,
		value: null,
	});

	const [modalDeleteShow, setDeleteModalShow] = useState({
		show: false,
		id: null,
	});

	const delRef = props.ondelete;
	const editRef = props.onsubjectEdit;

	useEffect(() => {
		return props.onsubjectView("subject/details"), props.onsubjectReset();
	}, []);

	const addSubject = (event) => {
		event.preventDefault();
		props.onsubjectAdd(subject);
	};

	const SearchHandler = (e) => {
		e.preventDefault();
		props.onSearch(query);
	};

	if (props.loading) {
		return <Spinner />;
	}

	if (props.error) {
		toast(props.error, {
			position: "top-center",
			type: "error",
			toastId: "custom-id",
		});
	}
	if (props.msg) {
		toast(props.msg, {
			position: "top-center",
			type: "success",
			toastId: "custom-id",
		});
	}
	console.log(props.subject.data);

	return (
		<>
			<Container>
				<Row>
					<Col>
						<form className="form-inline">
							<div className="form-group">
								<input
									className="form-control"
									type="text"
									name="subject"
									placeholder="Create new subject"
									onChange={(e) => {
										setsubject(e.target.value);
									}}
									style={{ margin: "0px 5px" }}
								/>
							</div>
							<Button onClick={addSubject}>Add</Button>
						</form>
					</Col>

					<Col>
						<div
							style={{ float: "right", marginBottom: "-10px" }}
							className="form-inline"
						>
							<input
								type="search"
								placeholder="  Enter a keyword"
								className="form-control"
								onChange={(e) => {
									setquery(e.target.value);
								}}
								value={query}
								style={{ margin: "0px 5px" }}
							/>
							<Button variant="dark" onClick={SearchHandler}>
								Search
							</Button>
						</div>
					</Col>

					<Table
						responsive
						striped
						bordered
						hover
						style={{ textAlign: "center", marginTop: "15px" }}
					>
						<thead>
							<tr>
								<th style={{ width: "15%" }}>S.N.</th>
								<th>Subject</th>
								<th>Action</th>
							</tr>
						</thead>
						{props.subject?.data?.length > 0 ? (
							<tbody>
								{props.subject?.data.map((value, i) => {
									return (
										<tr key={value.id}>
											<td>{i + 1}</td>
											<td>{value.Subject}</td>
											<td>
												<FontAwesomeIcon
													icon={faEdit}
													style={{ margin: "0px 5px" }}
													onClick={() =>
														setEditModalShow({
															show: true,
															id: value.id,
															value: value.Subject,
														})
													}
												/>

												<FontAwesomeIcon
													icon={faTrash}
													onClick={() =>
														setDeleteModalShow({ show: true, id: value.id })
													}
												/>
											</td>
										</tr>
									);
								})}
							</tbody>
						) : (
							<tbody>
								<tr>
									<td colSpan="6" className="text-center">
										No Subject Found
									</td>
								</tr>
							</tbody>
						)}
					</Table>

					{props.subject?.data?.length > 0 ? (
						<Pagination>
							<Pagination.First
								onClick={() =>
									props.onsubjectView(props.subject?.first_page_url)
								}
							/>
							{[...props.subject?.links].map((value, i) => {
								return value.url === null ? null : (
									<Pagination.Item
										active={value.active}
										key={i.toString()}
										onClick={() => props.onsubjectView(value?.url)}
									>
										{removeTags(value.label)}
									</Pagination.Item>
								);
							})}
							<Pagination.Last
								onClick={() =>
									props.onsubjectView(props.subject?.last_page_url)
								}
							/>
						</Pagination>
					) : null}

					{modalEditShow.show ? (
						<EditModal
							show={modalEditShow.show}
							id={modalEditShow.id}
							name="Subject"
							value={modalEditShow.value}
							setValue={setEditModalShow}
							onHide={() =>
								setEditModalShow({ show: false, id: null, value: null })
							}
							onEditRef={editRef}
						/>
					) : null}

					{modalDeleteShow.show ? (
						<DeleteModal
							show={modalDeleteShow.show}
							id={modalDeleteShow.id}
							name="Subject"
							onHide={() => setDeleteModalShow({ show: false, id: null })}
							onDelRef={delRef}
						/>
					) : null}
				</Row>
			</Container>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		subject: state.subjectR.subject,
		msg: state.subjectR.msg,
		error: state.subjectR.error,
		loading: state.subjectR.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onSearch: (query) => dispatch(subjectSearch(query)),
		onsubjectReset: () => dispatch(subjectReset()),
		onsubjectAdd: (subject) => dispatch(subjectAdd(subject)),
		onsubjectView: (url) => dispatch(subjectView(url)),
		onsubjectEdit: (id, subject) => dispatch(subjectUpdate(id, subject)),
		ondelete: (subject) => dispatch(subjectDelete(subject)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CrudSubject);
