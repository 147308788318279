import React, { useEffect, useState } from "react";
import { Container, Row, Table, Button } from "react-bootstrap";

import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import Spinner from "../../../../components/spinner/Spinner";
import {
	gradeView,
	subjectClassPriorityView,
	subjectClassPriorityHide,
	SubjectClassResetState,
	gradeReset,
} from "../../../../store/actions";

import SubjectHideModal from "../../../../components/Modal/Subject/subjectHideModal";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const HideSubject = (props) => {
	const [grade, setgrade] = useState({ value: null, show: false });

	const [subjectHide, setsubjectHide] = useState({
		id: null,
		hide: null,
		grade: null,
	});

	const [modalHideShow, setHideModalShow] = useState({
		show: false,
		id: null,
	});

	const hideRef = props.onSubjectClassHide;

	useEffect(() => {
		return (
			props.onGradeView(),
			props.onSubjectClassResetState(),
			props.ongradeReset()
		);
	}, []);

	if (props.loading || props.gradeLoading) {
		return <Spinner />;
	}

	if (props.error) {
		toast(props.error, {
			position: "top-center",
			type: "error",
			toastId: "custom-id",
		});
	}
	if (props.msg) {
		toast(props.msg, {
			position: "top-center",
			type: "success",
			toastId: "custom-id",
		});
	}

	const viewHandler = (event) => {
		console.log("viewHandler");
		console.log(event.target.value);
		event.preventDefault();
		setgrade({
			value: event.target.value,
			show: true,
		});
		props.onSubjectClassView(event.target.value);
		props.onSubjectClassResetState();
	};
	return (
		<Container>
			<ToastContainer />
			<Row>
				<form className="form-inline col-md-4">
					<div className="form-group">
						<select className="form-control" onChange={viewHandler}>
							<option selected>Select Grade</option>
							{props.grade.map((value) => {
								return (
									<option
										value={value.Grade}
										key={value.id}
										selected={grade.value == value.Grade}
									>
										{value.Grade}
									</option>
								);
							})}
						</select>
					</div>
				</form>

				<Table
					responsive
					striped
					bordered
					hover
					style={{ textAlign: "center" }}
				>
					<thead>
						<tr>
							<th>Subject</th>
							<th>Grade</th>
							<th>Teacher</th>
							<th>Actions</th>
						</tr>
					</thead>
					{grade.show == true && props.subjectClassPriority.length != null ? (
						<tbody>
							{props.subjectClassPriority.map((value, i) => {
								return (
									<tr key={value.id}>
										<td>{value.subject.Subject}</td>
										<td>{value.grade.Grade}</td>
										<td>{value.user.FirstName}</td>
										<td>
											{value.hide === "0" ? (
												<FontAwesomeIcon
													icon={faEye}
													style={{ margin: "0px 5px" }}
													onClick={() => {
														setHideModalShow({
															show: true,
															id: value.id,
														});
														setsubjectHide({
															id: value.id,
															hide: "1",
															grade: value.grade.Grade,
														});
													}}
												/>
											) : (
												<FontAwesomeIcon
													icon={faEyeSlash}
													onClick={() => {
														setHideModalShow({
															show: true,
															id: value.id,
														});
														setsubjectHide({
															id: value.id,
															hide: "0",
															grade: value.grade.Grade,
														});
													}}
												/>
											)}
										</td>
									</tr>
								);
							})}
						</tbody>
					) : (
						<tbody>
							<tr>
								<td colSpan="6" className="text-center">
									Please Select Grade
								</td>
							</tr>
						</tbody>
					)}
				</Table>

				{modalHideShow.show ? (
					<SubjectHideModal
						show={modalHideShow.show}
						id={modalHideShow.id}
						state={subjectHide}
						setStateRef={setsubjectHide}
						onHideRef={hideRef}
						onHide={() => setHideModalShow({ show: false, id: null })}
					/>
				) : null}
			</Row>
		</Container>
	);
};

const mapStateToProps = (state) => {
	return {
		grade: state.gradeR.grade,
		gradeLoading: state.gradeR.loading,
		subjectClassPriority: state.subjectClassPriorityR.subjectClassPriority,
		msg: state.subjectClassPriorityR.msg,
		error: state.subjectClassPriorityR.error,
		loading: state.subjectClassPriorityR.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		ongradeReset: () => dispatch(gradeReset()),

		onSubjectClassResetState: () => dispatch(SubjectClassResetState()),

		onGradeView: () => dispatch(gradeView()),

		onSubjectClassView: (grade) => dispatch(subjectClassPriorityView(grade)),

		onSubjectClassHide: (id, hide, grade) =>
			dispatch(subjectClassPriorityHide(id, hide, grade)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HideSubject);
