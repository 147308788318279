import React from "react";

import { Route, Redirect } from "react-router-dom";

export const TeacherRoute = ({ component: Component, ...arg }) => {
	console.log(arg, "teacher route");
	return (
		<Route
			{...arg}
			path={arg.path}
			render={(props) => {
				if (arg.auth) {
					return <Component {...props} />;
				} else {
					return <Redirect to="/login" />;
				}
				// console.log(props);
			}}
		/>
	);
};
