import { Button, Modal } from "react-bootstrap";

const EditLessonPlanModal = (props) => {
	console.log(props);

	const clickHandler = () => {
		props.onEditRef(props.id, props.value);
		props.onHide();
	};
	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Edit {props.name}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<label for="Day">Day</label>
				<input
					type="text"
					className="form-control"
					value={props.data.day}
					onChange={(e) => {
						props.setValue({ day: e.target.value });
					}}
				/>
				<label for="Day">Date</label>
				<input
					type="text"
					className="form-control"
					value={props.data.date}
					onChange={(e) => {
						props.setValue({ date: e.target.value });
					}}
				/>
				<label for="Day">Lesson</label>
				<input
					type="text"
					className="form-control"
					value={props.data.lesson}
					onChange={(e) => {
						props.setValue({ date: e.target.value });
					}}
				/>
				<label for="Day">Topic</label>
				<input
					type="text"
					className="form-control"
					value={props.data.topic}
					onChange={(e) => {
						props.setValue({ date: e.target.value });
					}}
				/>
				<label for="Day">Objective</label>
				<input
					type="text"
					className="form-control"
					value={props.data.objective}
					onChange={(e) => {
						props.setValue({ date: e.target.value });
					}}
				/>
				<label for="Day">Classwork</label>
				<input
					type="text"
					className="form-control"
					value={props.data.classwork}
					onChange={(e) => {
						props.setValue({ date: e.target.value });
					}}
				/>
				<label for="Day">Homework</label>
				<input
					type="text"
					className="form-control"
					value={props.data.homework}
					onChange={(e) => {
						props.setValue({ date: e.target.value });
					}}
				/>
				<label for="Day">Flipped Class</label>
				<input
					type="text"
					className="form-control"
					value={props.data.flippedClass}
					onChange={(e) => {
						props.setValue({ date: e.target.value });
					}}
				/>
			</Modal.Body>
			<Modal.Footer style={{ justifyContent: "flex-start" }}>
				<Button onClick={clickHandler} className="btn-info">
					Edit
				</Button>
				<Button onClick={props.onHide}>Close</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default EditLessonPlanModal;
