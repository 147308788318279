import React from "react";
import { Row, Col, Container } from "react-bootstrap";

const NotFound = (props) => {
  return (
    <Container fluid>
      <Row className="justify-content-md-center">
        <Col className="text-center">
          <br />
          <br />
          <h1>404 | Not Found</h1>
          <br />
          <br />
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
