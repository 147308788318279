import { updateObject } from "../../shared/utility";

import {
  TERM_ADD,
  TERM_DELETE,
  TERM_VIEW,
  TERM_UPDATE,
  TERM_FAIL,
  TERM_START,
  TERM_RESET,
} from "../actions/actionTypes";

const initialState = {
  term: [],
  msg: null,
  error: null,
  loading: false,
};

const termReset = (state, action) => {
  return updateObject(state, {
    term: [],
    msg: null,
    error: null,
  });
};

const termAdd = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const termStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const termFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    msg: null,
  });
};
const termView = (state, action) => {
  return updateObject(state, {
    loading: false,
    term: action.term,
  });
};

const termDelete = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const termUpdate = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const termReducer = (state = initialState, action) => {
  switch (action.type) {
    case TERM_START:
      return termStart(state, action);
    case TERM_ADD:
      return termAdd(state, action);
    case TERM_VIEW:
      return termView(state, action);
    case TERM_DELETE:
      return termDelete(state, action);
    case TERM_UPDATE:
      return termUpdate(state, action);
    case TERM_FAIL:
      return termFail(state, action);
    case TERM_RESET:
      return termReset(state, action);
    default:
      return state;
  }
};

export default termReducer;
