import { Button, Modal } from "react-bootstrap";

const UserAddModal = (props) => {
  console.log(props);

  const clickHandler = () => {
    props.onAddRef(
      props.state.firstName,
      props.state.lastName,
      props.state.email,
      props.state.role
    );
    props.onHide();

    console.log(props.state);
  };

  const selectHandler = (e) => {
    e.preventDefault();
    props.setStateRef((prev) => {
      console.log(prev);
      return { ...prev, role: e.target.value };
    });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton onClick={props.onHide}>
        <Modal.Title id="contained-modal-title-vcenter">
          Add {props.name}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <label for="firstName">First Name:</label>
        <input
          type="text"
          placeholder="Enter first name"
          className="form-control"
          onChange={(e) => {
            e.preventDefault();
            props.setStateRef((prev) => {
              //   console.log(prev.term);
              console.log(prev);
              return { ...prev, firstName: e.target.value };
            });
          }}
        />
        <br />

        <label for="lastName">Last Name:</label>
        <input
          type="text"
          placeholder="Enter last name"
          className="form-control"
          onChange={(e) => {
            e.preventDefault();
            props.setStateRef((prev) => {
              //   console.log(prev.term);
              console.log(prev);
              return { ...prev, lastName: e.target.value };
            });
          }}
        />
        <br />

        <label for="email">Email:</label>
        <input
          type="email"
          placeholder="Enter email"
          className="form-control"
          onChange={(e) => {
            e.preventDefault();
            props.setStateRef((prev) => {
              //   console.log(prev.term);
              console.log(prev);
              return { ...prev, email: e.target.value };
            });
          }}
        />
        <br />

        <label for="role">User Type:</label>
        <select className="form-control" id="role" onChange={selectHandler}>
          <option value="null" selected>
            Select User Type
          </option>
          <option value="1">Admin</option>
          <option value="2">Teacher</option>
        </select>
      </Modal.Body>

      <Modal.Footer style={{ justifyContent: "flex-start" }}>
        <Button variant="primary" onClick={clickHandler}>
          Add
        </Button>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserAddModal;
