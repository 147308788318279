//AUTH
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_CHANGE_PASSWORD = "AUTH_CHANGE_PASSWORD";
export const AUTH_RESET_PASSWORD = "AUTH_RESET_PASSWORD";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";

//GRADE
export const GRADE_ADD = "GRADE_ADD";
export const GRADE_VIEW = "GRADE_VIEW";
export const GRADE_UPDATE = "GRADE_UPDATE";
export const GRADE_DELETE = "GRADE_DELETE";
export const GRADE_FAIL = "GRADE_FAIL";
export const GRADE_START = "GRADE_START";
export const GRADE_RESET = "GRADE_RESET";
export const GRADE_PAGINATE = "GRADE_PAGINATE";

//SUBJECT
export const SUBJECT_ADD = "SUBJECT_ADD";
export const SUBJECT_VIEW = "SUBJECT_VIEW";
export const SUBJECT_UPDATE = "SUBJECT_UPDATE";
export const SUBJECT_DELETE = "SUBJECT_DELETE";
export const SUBJECT_FAIL = "SUBJECT_FAIL";
export const SUBJECT_START = "SUBJECT_START";
export const SUBJECT_RESET = "SUBJECT_RESET";
export const SUBJECT_SEARCH = "SUBJECT_SEARCH";

//TERM
export const TERM_ADD = "TERM_ADD";
export const TERM_VIEW = "TERM_VIEW";
export const TERM_UPDATE = "TERM_UPDATE";
export const TERM_DELETE = "TERM_DELETE";
export const TERM_FAIL = "TERM_FAIL";
export const TERM_START = "TERM_START";
export const TERM_RESET = "TERM_RESET";

//Lesson Plan
export const LESSONPLAN_ADD = "LESSONPLAN_ADD";
export const LESSONPLAN_VIEW = "LESSONPLAN_VIEW";
export const LESSONPLAN_UPDATE = "LESSONPLAN_UPDATE";
export const LESSONPLAN_DELETE = "LESSONPLAN_DELETE";
export const LESSONPLAN_FAIL = "LESSONPLAN_FAIL";
export const LESSONPLAN_START = "LESSONPLAN_START";
export const LESSONPLAN_RESET = "LESSONPLAN_RESET";

//BULK UPLOAD LESSON PLAN
export const LESSONPLAN_UPLOAD = "LESSONPLAN_UPLOAD";
export const LESSONPLAN_UPLOAD_PLUSTWO = "LESSONPLAN_UPLOAD_PLUSTWO";

//User
export const USER_ADD = "USER_ADD";
export const USER_VIEW = "USER_VIEW";
export const USER_UPDATE = "USER_UPDATE";
export const USER_DELETE = "USER_DELETE";
export const USER_FAIL = "USER_FAIL";
export const USER_START = "USER_START";
export const USER_RESET = "USER_RESET";
export const USER_SEARCH = "USER_SEARCH";

//Teacher
export const TEACHER_ASSIGN = "TEACHER_ASSIGN";
export const TEACHER_VIEW = "TEACHER_VIEW";
export const TEACHER_UPDATE = "TEACHER_UPDATE";
export const TEACHER_DELETE = "TEACHER_DELETE";
export const TEACHER_FAIL = "TEACHER_FAIL";
export const TEACHER_START = "TEACHER_START";
export const TEACHER_SHOW = "TEACHER_SHOW";
export const TEACHER_RESET = "TEACHER_RESET";
export const BULK_TEACHER_ASSIGN = "BULK_TEACHER_ASSIGN";
export const BULK_TEACHER_DELETE = "BULK_TEACHER_DELETE";
export const DASHBOARD = "DASHBOARD";
export const HOME_SEARCH = "HOME_SEARCH";

//Subject Class Priority
export const SUBJECT_CLASS_PRIORITY_START = "SUBJECT_CLASS_PRIORITY_START";
export const SUBJECT_CLASS_PRIORITY_FAIL = "SUBJECT_CLASS_PRIORITY_FAIL";
export const SUBJECT_CLASS_PRIORITY_RESET = "SUBJECT_CLASS_PRIORITY_RESET";
export const SUBJECT_CLASS_PRIORITY_VIEW = "SUBJECT_CLASS_PRIORITY_VIEW";
export const SUBJECT_CLASS_PRIORITY_UPDATE = "SUBJECT_CLASS_PRIORITY_UPDATE";
export const SUBJECT_CLASS_PRIORITY_DELETE = "SUBJECT_CLASS_PRIORITY_DELETE";
export const SUBJECT_CLASS_PRIORITY_HIDE = "SUBJECT_CLASS_PRIORITY_HIDE";
export const SUBJECT_CLASS_PRIORITY_GET_WEEK =
	"SUBJECT_CLASS_PRIORITY_GET_WEEK";
export const SUBJECT_CLASS_PRIORITY_DISPLAY_LESSON =
	"SUBJECT_CLASS_PRIORITY_DISPLAY_LESSON";
