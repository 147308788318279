import axios from "axios";
import {
	AUTH_START,
	AUTH_SUCCESS,
	AUTH_FAIL,
	AUTH_LOGOUT,
	AUTH_CHANGE_PASSWORD,
	AUTH_RESET_PASSWORD,
	SET_AUTH_REDIRECT_PATH,
} from "./actionTypes";

export const authStart = () => {
	return {
		type: AUTH_START,
	};
};

export const resetPassword = (msg) => {
	return {
		type: AUTH_RESET_PASSWORD,
		msg: msg,
	};
};

export const changePassword = (msg) => {
	return {
		type: AUTH_CHANGE_PASSWORD,
		msg: msg,
	};
};

export const authSuccess = (token, user) => {
	return {
		type: AUTH_SUCCESS,
		idToken: token,
		user: user,
		role:
			JSON.parse(JSON.stringify(user))?.role === undefined
				? JSON.parse(user)?.role
				: JSON.parse(JSON.stringify(user))?.role,
	};
};

export const authFail = (error) => {
	return {
		type: AUTH_FAIL,
		error: error,
	};
};

export const checkAuthTimeout = (expirationTime) => {
	return (dispatch) => {
		setTimeout(() => {
			dispatch(logout());
		}, expirationTime * 1000);
	};
};

export const logout = (msg) => {
	localStorage.removeItem("token");
	localStorage.removeItem("user");
	localStorage.removeItem("expireTime");
	return {
		type: AUTH_LOGOUT,
		msg: msg,
	};
};

export const setAuthRedirectPath = (path) => {
	return {
		type: SET_AUTH_REDIRECT_PATH,
		path: path,
	};
};

export const auth = (email, password) => {
	// console.log(email);
	return async (dispatch) => {
		dispatch(authStart());

		const authData = {
			email: email,
			password: password,
		};

		await axios
			.post("/login", authData)
			.then((response) => {
				const expireDate = new Date(
					new Date().getTime() + response.data.response.expiresIn * 1000
				);

				localStorage.setItem(
					"token",
					response.data.response.data.access_token.token
				);
				localStorage.setItem(
					"user",
					JSON.stringify(response.data.response.user)
				);
				localStorage.setItem("expireTime", expireDate);
				dispatch(
					authSuccess(
						response.data.response.data.access_token.token,
						response.data.response.user
					)
				);
				dispatch(checkAuthTimeout(response.data.response.expiresIn));
			})
			.catch((err) => {
				console.log("err", err?.data?.response?.message);
				err.data?.response?.message
					? dispatch(authFail(err.data?.response?.message))
					: err.data?.message
					? dispatch(authFail(err.data?.message))
					: err.data?.response
					? dispatch(authFail(err.data?.response))
					: dispatch(authFail("Network is not Established"));
			});
	};
};

export const authChangePassword = (password) => {
	return async (dispatch) => {
		const userId = JSON.parse(localStorage.getItem("user")).id;

		const authData = {
			password: password,
			id: userId,
		};

		await axios
			.post("/changePassword", authData)
			.then((response) => {
				localStorage.removeItem("user");
				localStorage.setItem(
					"user",
					JSON.stringify(response.data.response.user)
				);
				response.data.response?.message
					? dispatch(changePassword(response.data?.response?.message))
					: dispatch(changePassword("Network is not Established"));
			})
			.catch((err) => {
				err.data?.message
					? dispatch(authFail(err.data?.message))
					: err.data?.response
					? dispatch(authFail(err.data?.response?.message))
					: dispatch(authFail("Network is not Established"));
			});
	};
};

export const authResetPassword = (id) => {
	return async (dispatch) => {
		const data = {
			userId: id,
		};

		await axios
			.post("/resetPassword", data)
			.then((response) => {
				console.log(response, "From response");
				response.data?.response
					? dispatch(resetPassword(response.data?.response))
					: dispatch(resetPassword("Network is not Established"));
			})
			.catch((err) => {
				console.log(err);
				err.data?.message
					? dispatch(authFail(err.data?.message))
					: err.data?.response
					? dispatch(authFail(err.data?.response))
					: dispatch(authFail("Network is not Established"));
			});
	};
};

export const authCheckState = () => {
	console.log("I am from authCheckState");
	return async (dispatch) => {
		const token = localStorage.getItem("token");
		if (!token) {
			dispatch(logout());
		} else {
			const expiryDate = new Date(localStorage.getItem("expireTime"));
			if (expiryDate < new Date()) {
				dispatch(logout());
			} else {
				const user = localStorage.getItem("user");
				await dispatch(authSuccess(token, user));
				dispatch(
					await checkAuthTimeout(
						(expiryDate.getTime() - new Date().getTime()) / 1000
					)
				);
				console.log("Hello from auth js redux");
			}
		}
	};
};
