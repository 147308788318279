import React, { useEffect } from "react";
import { logout } from "../../store/actions/index";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

const Logout = (props) => {
  useEffect(() => props.onLogout(), []);
  return <Redirect to="/" />;
};

const mapDispathToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(logout()),
  };
};

export default connect(null, mapDispathToProps)(Logout);
