import React, { useEffect, useState } from "react";
import { Container, Row, Table } from "react-bootstrap";

import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteModal from "../../../../components/Modal/DeleteModal";
import { connect } from "react-redux";
import Spinner from "../../../../components/spinner/Spinner";
import {
	gradeView,
	teacherView,
	teacherUpdate,
	teacherShow,
	teacherReset,
	teacherDelete,
} from "../../../../store/actions";
import TeacherEditModal from "../../../../components/Modal/Teacher/TeacherEditModal";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const TeacherAssign = (props) => {
	console.log("Hello from crud lessonplan js");

	const [grade, setgrade] = useState({ value: null, show: false });

	const [assignTeacher, setassignTeacher] = useState({
		grade_id: null,
		subject_id: null,
		user_id: null,
	});

	const [modalEditShow, setEditModalShow] = useState({
		show: false,
		id: null,
	});

	const [modalDeleteShow, setDeleteModalShow] = useState({
		show: false,
		id: null,
	});

	const editRef = props.onteacherEdit;
	const delRef = props.onteacherDelete;

	useEffect(() => {
		return props.onGradeView(), props.onTeacherView(), props.onteacherReset();
	}, []);

	// useEffect(() => props.onTeacherView(), []);

	if (props.loading) {
		return <Spinner />;
	}

	if (props.error) {
		toast(props.error, {
			position: "top-center",
			type: "error",
			toastId: "custom-id",
		});
	}
	if (props.msg) {
		toast(props.msg, {
			position: "top-center",
			type: "success",
			toastId: "custom-id",
		});
	}

	const viewHandler = (event) => {
		console.log("viewHandler");
		console.log(event.target.value);
		event.preventDefault();
		setgrade({
			value: event.target.value,
			show: true,
		});
		props.onTeacherShow(event.target.value);
	};

	return (
		<>
			<Container>
				<Row>
					<form className="form-inline col-md-4">
						<div className="form-group">
							<select className="form-control" onChange={viewHandler}>
								<option selected>Select Grade</option>
								{props.grade.map((value) => {
									return (
										<option
											value={value.id}
											key={value.id}
											selected={grade.value == value.id}
										>
											{value.Grade}
										</option>
									);
								})}
							</select>
						</div>
					</form>

					<Table
						responsive
						striped
						bordered
						hover
						style={{ textAlign: "center" }}
					>
						<thead>
							<tr>
								<th style={{ width: "15%" }}>S.N.</th>
								<th>First Name</th>
								<th>Last Name</th>
								<th>Subject</th>
								<th>Grade</th>
								<th>Action</th>
							</tr>
						</thead>
						{grade.show == true && props.teacherShow.length != null ? (
							<tbody>
								{props.teacherShow.map((value, i) => {
									return (
										<tr key={value.id}>
											<td>{i + 1}</td>
											<td>{value.user.FirstName}</td>
											<td>{value.user.LastName}</td>
											<td>{value.subject.Subject}</td>
											<td>{value.grade.Grade}</td>
											<td>
												<FontAwesomeIcon
													icon={faEdit}
													style={{ margin: "0px 5px" }}
													onClick={() => {
														setEditModalShow({
															show: true,
															id: value.id,
														});
														setassignTeacher({
															grade_id: value.grade_id,
															subject_id: value.subject_id,
															user_id: value.user_id,
														});
													}}
												/>

												<FontAwesomeIcon
													icon={faTrash}
													onClick={() =>
														setDeleteModalShow({ show: true, id: value.id })
													}
												/>
											</td>
										</tr>
									);
								})}
							</tbody>
						) : (
							<tbody>
								<tr>
									<td colSpan="6" className="text-center">
										Please Select Grade
									</td>
								</tr>
							</tbody>
						)}
					</Table>

					{modalEditShow.show ? (
						<TeacherEditModal
							show={modalEditShow.show}
							id={modalEditShow.id}
							state={assignTeacher}
							setStateRef={setassignTeacher}
							grade_id={grade.value}
							data={props.teacher}
							name="Teacher Assign"
							onEditRef={editRef}
							onHide={() =>
								setEditModalShow({ show: false, id: null, value: null })
							}
						/>
					) : null}

					{modalDeleteShow.show ? (
						<DeleteModal
							show={modalDeleteShow.show}
							id={modalDeleteShow.id}
							name="Teacher Assign"
							onHide={() => setDeleteModalShow({ show: false, id: null })}
							onDelRef={delRef}
							grade_id={grade.value}
						/>
					) : null}
				</Row>
			</Container>
			<ToastContainer />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		grade: state.gradeR.grade,
		teacher: state.teacherR.teacher,
		teacherShow: state.teacherR.teacherShow,
		msg: state.teacherR.msg,
		error: state.teacherR.error,
		loading: state.teacherR.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onteacherDelete: (grade, id) => dispatch(teacherDelete(grade, id)),

		onteacherReset: () => dispatch(teacherReset()),

		onGradeView: () => dispatch(gradeView()),
		onTeacherShow: (id) => dispatch(teacherShow(id)),

		onteacherEdit: (id, user_id, subject_id, grade_id) =>
			dispatch(teacherUpdate(id, user_id, subject_id, grade_id)),

		onTeacherView: () => dispatch(teacherView()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TeacherAssign);
