import axios from "axios";

import {
	TEACHER_ASSIGN,
	TEACHER_VIEW,
	TEACHER_DELETE,
	TEACHER_UPDATE,
	TEACHER_START,
	TEACHER_FAIL,
	TEACHER_SHOW,
	TEACHER_RESET,
	BULK_TEACHER_ASSIGN,
	BULK_TEACHER_DELETE,
	DASHBOARD,
	HOME_SEARCH,
} from "./actionTypes";

export const handleteacherReset = () => {
	return {
		type: TEACHER_RESET,
	};
};

export const teacherCreate = (msg) => {
	return {
		type: TEACHER_ASSIGN,
		msg: msg,
	};
};

export const teacherStart = () => {
	return {
		type: TEACHER_START,
	};
};

export const teacherFail = (msg) => {
	return {
		type: TEACHER_FAIL,
		error: msg,
	};
};

export const teacher = (teacher) => {
	return {
		type: TEACHER_VIEW,
		teacher: teacher,
	};
};

export const teacherShowId = (teacher) => {
	return {
		type: TEACHER_SHOW,
		teacher: teacher,
	};
};

export const teacherDestroy = (msg) => {
	return {
		type: TEACHER_DELETE,
		msg: msg,
	};
};

export const teacherPatch = (msg) => {
	return {
		type: TEACHER_UPDATE,
		msg: msg,
	};
};

export const handleDashboard = (data) => {
	return {
		type: DASHBOARD,
		dashboardData: data,
	};
};
export const handleBulkTeacherAssign = (msg) => {
	return {
		type: BULK_TEACHER_ASSIGN,
		msg: msg,
	};
};

export const handleBulkTeacherDelete = (msg) => {
	return {
		type: BULK_TEACHER_DELETE,
		msg: msg,
	};
};

export const handleSearch = (data) => {
	return {
		type: HOME_SEARCH,
		dashboardData: data,
	};
};

export const teacherReset = () => {
	return (dispatch) => {
		dispatch(handleteacherReset());
	};
};

export const dashboard = (url = "/dashboard") => {
	return async (dispatch) => {
		dispatch(teacherStart());
		await axios
			.get(url)
			.then((response) => {
				if (response?.data?.response) {
					const newData = {
						currentPage: response.data?.response?.current_page,
						data: response.data?.response?.data,
						firstPageUrl: response.data?.response?.first_page_url,
						lastPageUrl: response.data?.response?.last_page_url,
						link: response.data?.response?.links,
					};
					dispatch(handleDashboard(newData));
				} else {
					dispatch(teacherFail("Network is not Established"));
				}
			})
			.catch((err) => {
				console.log("Here", err);
				err.data?.message
					? dispatch(teacherFail(err.data?.message))
					: err.data?.response
					? dispatch(teacherFail(err.data?.response))
					: dispatch(teacherFail("Network is not Established"));
			});
	};
};

export const search = (query) => {
	return async (dispatch) => {
		dispatch(teacherStart());
		await axios
			.post("/home/search", { search: query })
			.then((response) => {
				if (response?.data?.response) {
					const newData = {
						currentPage: response.data?.response?.current_page,
						data: response.data?.response?.data,
						firstPageUrl: response.data?.response?.first_page_url,
						lastPageUrl: response.data?.response?.last_page_url,
						link: response.data?.response?.links,
					};
					dispatch(handleSearch(newData));
				} else {
					dispatch(teacherFail("Network is not Established"));
				}
			})
			.catch((err) => {
				err.data?.message
					? dispatch(teacherFail(err.data?.message))
					: err.data?.response
					? dispatch(teacherFail(err.data?.response))
					: dispatch(teacherFail("Network is not Established"));
			});
	};
};

export const BulkTeacherAssign = (file) => {
	return async (dispatch) => {
		dispatch(teacherStart());
		await axios
			.post("/teacher/import", { file: file })
			.then((response) => {
				response?.data?.response
					? dispatch(handleBulkTeacherAssign(response?.data?.response))
					: response.data?.response?.message
					? dispatch(handleBulkTeacherAssign(response?.data?.response?.message))
					: dispatch(teacherFail("Network is not Established"));
			})
			.catch((err) => {
				console.log(err.data?.response);
				err.data?.message
					? dispatch(teacherFail(err.data?.message))
					: err.data?.response
					? dispatch(teacherFail(err.data?.response))
					: dispatch(teacherFail("Network is not Established"));
			});
		dispatch(dashboard());
	};
};

export const BulkTeacherDelete = () => {
	return async (dispatch) => {
		dispatch(teacherStart());
		await axios
			.post("/teacher/bulkDelete", { _method: "DELETE" })
			.then((response) => {
				response.data?.response?.message
					? dispatch(handleBulkTeacherDelete(response.data?.response?.message))
					: response.data?.response
					? dispatch(handleBulkTeacherDelete(response.data?.response))
					: dispatch(
							handleBulkTeacherDelete("Something went wrong! Please try again.")
					  );
			})
			.catch((err) => {
				console.log(err);
				err.data?.message
					? dispatch(teacherFail(err.data?.message))
					: err.data?.response
					? dispatch(teacherFail(err.data?.response))
					: dispatch(teacherFail("Network is not Established"));
			});
		dispatch(dashboard());
	};
};

export const teacherAssign = (grade_id, subject_id, user_id) => {
	console.log("Hello I'm from teacherA.js");
	console.log(teacher);

	return async (dispatch) => {
		dispatch(teacherStart());
		await axios
			.post("/teacher/store", {
				grade_id: grade_id,
				subject_id: subject_id,
				user_id: user_id,
			})
			.then((response) => {
				response.data?.response?.message
					? dispatch(teacherCreate(response.data?.response?.message))
					: response.data?.response
					? dispatch(teacherCreate(response.data?.response))
					: dispatch(teacherCreate("Network is not Established"));

				if (response.data.isSuccess == false) {
					dispatch(teacherFail(response.data.response?.message));
				}
			})
			.catch((err) => {
				console.log(err);
				err.data?.message
					? dispatch(teacherFail(err.data?.message))
					: err.data?.response
					? dispatch(teacherFail(err.data?.response))
					: dispatch(teacherFail("Network is not Established"));
			});
		dispatch(teacherView());
	};
};

export const teacherDelete = (grade, teacher) => {
	return async (dispatch) => {
		// dispatch(teacherStart());
		await axios
			.post("teacher/" + teacher, { _method: "DELETE" })
			.then((response) => {
				response.data?.response?.message
					? dispatch(teacherDestroy(response.data?.response?.message))
					: response.data?.response
					? dispatch(teacherDestroy(response.data?.response))
					: dispatch(teacherDestroy("Something went wrong! Please try again."));
			})
			.catch((err) => {
				console.log(err);
				err.data?.message
					? dispatch(teacherFail(err.data?.message))
					: err.data?.response
					? dispatch(teacherFail(err.data?.response))
					: dispatch(teacherFail("Network is not Established"));
			});

		grade === undefined ? dispatch(dashboard()) : dispatch(teacherShow(grade));
	};
};

export const teacherUpdate = (
	id,
	user_id,
	subject_id,
	grade_id,
	fromDashboard
) => {
	return async (dispatch) => {
		// dispatch(teacherStart());
		await axios
			.post("teacher/" + id, {
				user_id: user_id,
				subject_id: subject_id,
				grade_id: grade_id,
				_method: "PUT",
			})
			.then((response) => {
				response.data?.response?.message
					? dispatch(teacherPatch(response.data?.response?.message))
					: response.data?.response
					? dispatch(teacherPatch(response.data?.response))
					: dispatch(teacherPatch("Something went wrong! Please try again."));
			})
			.catch((err) => {
				console.log(err);
				err.data?.message
					? dispatch(teacherFail(err.data?.message))
					: err.data?.response
					? dispatch(teacherFail(err.data?.response))
					: dispatch(teacherFail("Network is not Established"));
			});
		fromDashboard === undefined
			? dispatch(teacherShow(grade_id))
			: dispatch(dashboard());
	};
};

export const teacherView = () => {
	return async (dispatch) => {
		dispatch(teacherStart());
		await axios
			.get("teacher/details")
			.then((response) => {
				console.log(response);
				response.data?.response
					? dispatch(teacher(response.data?.response))
					: dispatch(teacher("Network is not Established"));
			})
			.catch((err) => {
				err.data?.message
					? dispatch(teacherFail(err.data?.message))
					: err.data?.response
					? dispatch(teacherFail(err.data?.response))
					: dispatch(teacherFail("Network is not Established"));
			});
	};
};

export const teacherShow = (id) => {
	return async (dispatch) => {
		dispatch(teacherStart());
		await axios
			.get("teacher/" + id)
			.then((response) => {
				console.log(response.data);
				response.data?.response
					? dispatch(teacherShowId(response.data?.response))
					: dispatch(teacherShowId("Network is not Established"));
			})
			.catch((err) => {
				console.log(err);
				err?.data?.message
					? dispatch(teacherFail(err.data?.message))
					: err?.data?.response
					? dispatch(teacherFail(err.data?.response))
					: dispatch(teacherFail("Network is not Established"));
			});
	};
};
