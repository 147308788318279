import axios from "axios";

import {
  USER_ADD,
  USER_VIEW,
  USER_DELETE,
  USER_UPDATE,
  USER_START,
  USER_FAIL,
  USER_RESET,
  USER_SEARCH,
} from "./actionTypes";

export const handleuserReset = () => {
  return {
    type: USER_RESET,
  };
};

export const userCreate = (msg) => {
  return {
    type: USER_ADD,
    msg: msg,
  };
};

export const userStart = () => {
  return {
    type: USER_START,
  };
};

export const userFail = (msg) => {
  return {
    type: USER_FAIL,
    error: msg,
  };
};

export const user = (user) => {
  return {
    type: USER_VIEW,
    user: user,
  };
};

export const userDestroy = (msg) => {
  return {
    type: USER_DELETE,
    msg: msg,
  };
};

export const userPatch = (msg) => {
  return {
    type: USER_UPDATE,
    msg: msg,
  };
};

export const userReset = () => {
  return (dispatch) => {
    dispatch(handleuserReset());
  };
};

export const handleSearch = (user) => {
  return {
    type: USER_SEARCH,
    user: user,
  };
};

export const userAdd = (firstName, lastName, email, role) => {
  console.log("Hello I'm from userA.js");
  console.log(user);

  return async (dispatch) => {
    // dispatch(userStart());
    await axios
      .post("/user/store", {
        FirstName: firstName,
        LastName: lastName,
        email: email,
        role: role,
      })
      .then((response) => {
        response.data?.response?.message
          ? dispatch(userCreate(response.data?.response?.message))
          : response.data?.response
          ? dispatch(userCreate(response.data?.response))
          : dispatch(userCreate("Network is not Established"));

        if (response.data.isSuccess == false) {
          dispatch(userFail(response.data.response?.message));
        }
      })
      .catch((err) => {
        console.log(err);
        err.data?.message
          ? dispatch(userFail(err.data?.message))
          : err.data?.response
          ? dispatch(userFail(err.data?.response))
          : dispatch(userFail("Network is not Established"));
      });
    dispatch(userView());
  };
};

export const userDelete = (user) => {
  return async (dispatch) => {
    // dispatch(userStart());
    await axios
      .post("user/" + user, { _method: "DELETE" })
      .then((response) => {
        response.data?.response?.message
          ? dispatch(userDestroy(response.data?.response?.message))
          : response.data?.response
          ? dispatch(userDestroy(response.data?.response))
          : dispatch(userDestroy("Something went wrong! Please try again."));
      })
      .catch((err) => {
        console.log(err);
        err.data?.message
          ? dispatch(userFail(err.data?.message))
          : err.data?.response
          ? dispatch(userFail(err.data?.response))
          : dispatch(userFail("Network is not Established"));
      });
    dispatch(userView());
  };
};

export const userUpdate = (id, firstName, lastName, email, role) => {
  return async (dispatch) => {
    // dispatch(userStart());
    await axios
      .post("user/" + id, {
        FirstName: firstName,
        LastName: lastName,
        email: email,
        role: role,
        _method: "PUT",
      })
      .then((response) => {
        response.data?.response?.message
          ? dispatch(userPatch(response.data.response?.message))
          : response.data?.response
          ? dispatch(userPatch(response.data.response))
          : dispatch(userPatch("Something went wrong! Please try again."));
      })
      .catch((err) => {
        console.log(err);
        err.data?.message
          ? dispatch(userFail(err.data?.message))
          : err.data?.response
          ? dispatch(userFail(err.data?.response))
          : dispatch(userFail("Network is not Established"));
      });
    dispatch(userView());
  };
};

export const userView = (url = "user/details") => {
  return async (dispatch) => {
    dispatch(userStart());
    await axios
      .get(url)
      .then((response) => {
        console.log(response);
        response?.data?.response
          ? dispatch(user(response?.data?.response))
          : dispatch(user("Network is not Established"));
      })
      .catch((err) => {
        err?.data?.message
          ? dispatch(userFail(err?.data?.message))
          : err?.data?.response
          ? dispatch(userFail(err?.data?.response))
          : dispatch(userFail("Network is not Established"));
      });
  };
};

export const userSearch = (query) => {
  return async (dispatch) => {
    dispatch(userStart());
    await axios
      .post("user/search", { search: query })
      .then((response) => {
        console.log(response);
        response?.data?.response
          ? dispatch(handleSearch(response?.data?.response))
          : dispatch(handleSearch("Network is not Established"));
      })
      .catch((err) => {
        err?.data?.message
          ? dispatch(userFail(err?.data?.message))
          : err?.data?.response
          ? dispatch(userFail(err?.data?.response))
          : dispatch(userFail("Network is not Established"));
      });
  };
};
