import { Button, Modal } from "react-bootstrap";

const DeleteModal = (props) => {
	console.log(props, "from delete modal");
	console.log(props.id);

	const clickHandler = (event) => {
		if (props.grade_id !== undefined) {
			props.onDelRef(props.grade_id, props.id);
		} else if (props.week !== undefined) {
			props.onDelRef(props.id, props.subject_id, props.term_id, props.week);
		} else {
			props.onDelRef(props.id);
		}

		props.onHide();
	};
	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Delete</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>Are you sure you want to delete {props.name} ?</p>
			</Modal.Body>
			<Modal.Footer style={{ justifyContent: "flex-start" }}>
				<Button onClick={clickHandler} className="btn-danger">
					Delete
				</Button>
				<Button onClick={props.onHide}>Close</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default DeleteModal;
