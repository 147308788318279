import { Button, Modal } from "react-bootstrap";

const TeacherAssignModal = (props) => {
  const clickHandler = () => {
    props.onAssignRef(props.state.grade_id, props.state.subject_id, props.id);
    props.onHide();
    console.log(props.state);
  };

  const selectGradeHandler = (e) => {
    e.preventDefault();
    props.setStateRef((prev) => {
      return { ...prev, grade_id: e.target.value };
    });
  };

  const selectSubjectHandler = (e) => {
    e.preventDefault();
    props.setStateRef((prev) => {
      return { ...prev, subject_id: e.target.value };
    });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton onClick={props.onHide}>
        <Modal.Title id="contained-modal-title-vcenter">
          Assign Subject and Grade to {props.name}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <label for="grade">Grade:</label>
        <select
          className="form-control"
          id="grade"
          onChange={selectGradeHandler}
        >
          <option value="null" selected>
            Select Grade
          </option>
          {props.data.grade.map((value) => {
            return <option value={value.id}>{value.Grade}</option>;
          })}
        </select>

        <label for="subject">Subject:</label>
        <select
          className="form-control"
          id="subject"
          onChange={selectSubjectHandler}
        >
          <option value="null" selected>
            Select Subject
          </option>
          {props.data.subject.map((value) => {
            return <option value={value.id}>{value.Subject}</option>;
          })}
        </select>
      </Modal.Body>

      <Modal.Footer style={{ justifyContent: "flex-start" }}>
        <Button variant="primary" onClick={clickHandler}>
          Assign
        </Button>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TeacherAssignModal;
