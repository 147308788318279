import React from "react";
import { Button, Nav, NavDropdown } from "react-bootstrap";

import { Link } from "react-router-dom";

const TeacherNavComp = () => {
	return (
		<>
			<Nav.Link as={Link} to="/dashboard">
				Dashboard
			</Nav.Link>
			<Nav.Link as={Link} to="/juniorSchool">
				Create Lessons(1-10)
			</Nav.Link>
			<Nav.Link as={Link} to="/highSchool">
				Create Lessons(11-12)
			</Nav.Link>
			<Link to="/logout">
				<Button variant="primary">Logout</Button>
			</Link>
		</>
	);
};

export default TeacherNavComp;
