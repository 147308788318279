import { updateObject } from "../../shared/utility";

import {
  SUBJECT_ADD,
  SUBJECT_DELETE,
  SUBJECT_VIEW,
  SUBJECT_UPDATE,
  SUBJECT_FAIL,
  SUBJECT_START,
  SUBJECT_RESET,
  SUBJECT_SEARCH,
} from "../actions/actionTypes";

const initialState = {
  subject: [],
  msg: null,
  error: null,
  loading: false,
};

const subjectReset = (state, action) => {
  return updateObject(state, {
    subject: [],
    msg: null,
    error: null,
  });
};

const subjectAdd = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const subjectStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    // msg: null,
  });
};

const subjectFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    msg: null,
  });
};

const subjectView = (state, action) => {
  return updateObject(state, {
    loading: false,
    subject: action.subject,
  });
};

const subjectDelete = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const subjectUpdate = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const subjectSearch = (state, action) => {
  return updateObject(state, {
    loading: false,
    subject: action.subject,
  });
};

const subjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBJECT_START:
      return subjectStart(state, action);
    case SUBJECT_ADD:
      return subjectAdd(state, action);
    case SUBJECT_VIEW:
      return subjectView(state, action);
    case SUBJECT_DELETE:
      return subjectDelete(state, action);
    case SUBJECT_UPDATE:
      return subjectUpdate(state, action);
    case SUBJECT_FAIL:
      return subjectFail(state, action);
    case SUBJECT_RESET:
      return subjectReset(state, action);
    case SUBJECT_SEARCH:
      return subjectSearch(state, action);
    default:
      return state;
  }
};

export default subjectReducer;
