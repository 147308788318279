import React, { useEffect, useState } from "react";
import { Button, Container, Row, Table } from "react-bootstrap";

import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TermEditModal from "../../../components/Modal/Term/TermEditModal";
import DeleteModal from "../../../components/Modal/DeleteModal";
import { connect } from "react-redux";
import Spinner from "../../../components/spinner/Spinner";
import {
	termAdd,
	termDelete,
	termView,
	termUpdate,
	termReset,
} from "../../../store/actions";
import TermAddModal from "../../../components/Modal/Term/TermAddModal";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const CrudTerm = (props) => {
	console.log("Hello from crud term js");
	console.log(props.msg);
	const [term, setterm] = useState({
		term: null,
		startDate: null,
		endDate: null,
	});

	const [modalEditShow, setEditModalShow] = useState({
		show: false,
		id: null,
	});

	const [modalDeleteShow, setDeleteModalShow] = useState({
		show: false,
		id: null,
	});

	const [modalAddShow, setAddModalShow] = useState(false);

	const delRef = props.ondelete;
	const editRef = props.ontermEdit;

	const addRef = props.ontermAdd;

	useEffect(() => {
		return props.ontermView(), props.ontermReset();
	}, []);

	if (props.loading) {
		return <Spinner />;
	}

	if (props.error) {
		toast(props.error, {
			position: "top-center",
			type: "error",
			toastId: "custom-id",
		});
	}
	if (props.msg) {
		toast(props.msg, {
			position: "top-center",
			type: "success",
			toastId: "custom-id",
		});
	}

	return (
		<>
			<Container>
				<Row>
					<Button variant="primary" onClick={() => setAddModalShow(true)}>
						Add Semester
					</Button>
					<Table
						responsive
						striped
						bordered
						hover
						style={{ textAlign: "center", marginTop: "15px" }}
					>
						<thead>
							<tr>
								<th style={{ width: "15%" }}>S.N.</th>
								<th>Semester</th>
								<th>Start Date</th>
								<th>End Date</th>
								<th>Action</th>
							</tr>
						</thead>
						{props.term.length > 0 ? (
							<tbody>
								{props.term.map((value, i) => {
									return (
										<tr key={value.id}>
											<td>{i + 1}</td>
											<td>{value.term}</td>
											<td>{value.StartDate}</td>
											<td>{value.EndDate}</td>
											<td>
												<FontAwesomeIcon
													icon={faEdit}
													style={{ margin: "0px 5px" }}
													onClick={() => {
														setEditModalShow({
															show: true,
															id: value.id,
														});
														setterm({
															term: value.term,
															startDate: value.StartDate,
															endDate: value.EndDate,
														});
													}}
												/>

												<FontAwesomeIcon
													icon={faTrash}
													onClick={() =>
														setDeleteModalShow({ show: true, id: value.id })
													}
												/>
											</td>
										</tr>
									);
								})}
							</tbody>
						) : (
							<tbody>
								<tr>
									<td colSpan="6" className="text-center">
										No Term Found
									</td>
								</tr>
							</tbody>
						)}
					</Table>

					{modalAddShow ? (
						<TermAddModal
							show={modalAddShow}
							onAddRef={addRef}
							state={term}
							name="Term"
							setStateRef={setterm}
							onHide={() => setAddModalShow(false)}
						/>
					) : null}

					{modalEditShow.show ? (
						<TermEditModal
							show={modalEditShow.show}
							id={modalEditShow.id}
							state={term}
							name="Term"
							setStateRef={setterm}
							onHide={() =>
								setEditModalShow({ show: false, id: null, value: null })
							}
							onEditRef={editRef}
						/>
					) : null}

					{modalDeleteShow.show ? (
						<DeleteModal
							show={modalDeleteShow.show}
							id={modalDeleteShow.id}
							name="Term"
							onHide={() => setDeleteModalShow({ show: false, id: null })}
							onDelRef={delRef}
						/>
					) : null}
				</Row>
			</Container>
			<ToastContainer />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		term: state.termR.term,
		msg: state.termR.msg,
		error: state.termR.error,
		loading: state.termR.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		ontermReset: () => {
			dispatch(termReset());
		},

		ontermAdd: (term, startDate, endDate) =>
			dispatch(termAdd(term, startDate, endDate)),

		ontermView: () => dispatch(termView()),

		ontermEdit: (id, term, startDate, endDate) =>
			dispatch(termUpdate(id, term, startDate, endDate)),

		ondelete: (term) => dispatch(termDelete(term)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CrudTerm);
