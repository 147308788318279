import { Button, Modal } from "react-bootstrap";

const UserEditModal = (props) => {
  console.log(props.id);
  console.log("props.id");

  const clickHandler = () => {
    props.onEditRef(
      props.id,
      props.state.firstName,
      props.state.lastName,
      props.state.email,
      props.state.role
    );
    props.onHide();
  };

  const selectHandler = (e) => {
    e.preventDefault();
    props.setStateRef((prev) => {
      console.log(prev);
      return { ...prev, role: e.target.value };
    });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label for="firstName">First Name: </label>
        <input
          type="text"
          placeholder="Enter first name"
          className="form-control"
          value={props.state.firstName}
          onChange={(e) => {
            e.preventDefault();
            props.setStateRef((prev) => {
              console.log(prev);
              return { ...prev, firstName: e.target.value };
            });
          }}
        />
        <br />

        <label for="lastName">Last Name:</label>
        <input
          type="text"
          placeholder="Enter last name"
          className="form-control"
          value={props.state.lastName}
          onChange={(e) => {
            e.preventDefault();
            props.setStateRef((prev) => {
              //   console.log(prev.term);
              console.log(prev);
              return { ...prev, lastName: e.target.value };
            });
          }}
        />
        <br />

        <label for="email">Email:</label>
        <input
          type="email"
          value={props.state.email}
          placeholder="Enter email"
          className="form-control"
          onChange={(e) => {
            e.preventDefault();
            props.setStateRef((prev) => {
              //   console.log(prev.term);
              console.log(prev);
              return { ...prev, email: e.target.value };
            });
          }}
        />
        <br />

        <label for="role">User Type:</label>
        <select className="form-control" id="role" onChange={selectHandler}>
          <option value="1" selected={props.state.role == "admin" ? 1 : 0}>
            Admin
          </option>
          <option value="2" selected={props.state.role == "teacher" ? 1 : 0}>
            Teacher
          </option>
        </select>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "flex-start" }}>
        <Button onClick={clickHandler} className="btn-info">
          Edit
        </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserEditModal;
