import React, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "../../components/spinner/Spinner";
import { Redirect } from "react-router";
import { authChangePassword } from "../../store/actions/authA";
import { ToastContainer, toast } from "react-toastify";
const PasswordChange = (props) => {
	const [viewPassword, setViewPassword] = useState(false);
	const [errorOnPassword, setErrorOnPassword] = useState(false);

	if (props.loading) {
		console.log("loading");
		return <Spinner />;
	}
	let errorMsg = null;

	if (props.error) {
		errorMsg = <p className="alert alert-danger">{props.error}</p>;
	}

	let authRedirect = null;

	if (props.isAuthenticated) {
		authRedirect = <Redirect to="/dashboard" />;
	}

	const submitHandler = (event) => {
		event.preventDefault();
		if (event.target.password.value === event.target.confirmPassword.value) {
			props.onChangePassword(event.target.password.value);
		} else {
			setErrorOnPassword(!errorOnPassword);
			toast("Passwords do not match", {
				position: "top-center",
				type: "error",
				toastId: "custom-id",
			});
		}
	};
	return (
		<>
			<Container>
				<Row className="justify-content-center">
					<Col md={8}>
						<Card>
							<Card.Header>Change Password</Card.Header>
							<Card.Body>
								{authRedirect}
								{errorMsg}
								<form onSubmit={submitHandler}>
									<div className="form-group row">
										<label
											for="email"
											className="col-md-4 col-form-label text-md-right"
										>
											Password :{" "}
										</label>
										<div className="col-md-6">
											<input
												placeholder="Password"
												type={viewPassword ? "text" : "password"}
												name="password"
												className={
													errorOnPassword ? "border border-danger" : ""
												}
											/>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-md-5 col-form-label text-md-right">
											<input
												className="mr-1"
												type="checkbox"
												checked={viewPassword}
												onChange={(event) => {
													setViewPassword(!viewPassword);
												}}
											/>
											Show Password
										</div>
									</div>
									<div className="form-group row">
										<label
											for="email"
											className="col-md-4 col-form-label text-md-right"
										>
											Confirm Password :{" "}
										</label>
										<div className="col-md-6">
											<input
												placeholder="Confirm Password"
												type={viewPassword ? "text" : "password"}
												name="confirmPassword"
												className={
													errorOnPassword ? "border border-danger" : ""
												}
											/>
										</div>
									</div>
									<div className="form-group row mb-0">
										<div className="col-md-8 offset-md-4">
											<button
												className="btn btn-primary"
												style={{
													backgroundColor: "#26a69a",
													borderColor: "white",
													textTransform: "uppercase",
												}}
											>
												Update Password
											</button>
										</div>
									</div>
								</form>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
			<ToastContainer />
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		loading: state.authR.loading,
		error: state.authR.error,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		onChangePassword: (password) => dispatch(authChangePassword(password)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(PasswordChange);
