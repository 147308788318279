import React from "react";
import { Button, Nav, NavDropdown } from "react-bootstrap";

import { Link } from "react-router-dom";

const AdminNavComp = (props) => {
	return (
		<>
			<Nav.Link as={Link} to="/dashboard">
				Dashboard
			</Nav.Link>
			<NavDropdown title="User" id="basic-nav-dropdown">
				<NavDropdown.Item as={Link} to="/Users">
					Users
				</NavDropdown.Item>
				<NavDropdown.Divider />
				<NavDropdown.Item as={Link} to="/teacherAssigned">
					Teachers Assigned
				</NavDropdown.Item>
			</NavDropdown>

			<NavDropdown title="Subject" id="basic-nav-dropdown">
				<NavDropdown.Item as={Link} to="/Subject">
					Subject
				</NavDropdown.Item>
				<NavDropdown.Divider />
				<NavDropdown.Item as={Link} to="/subjectPriority">
					Subject Priority
				</NavDropdown.Item>
				<NavDropdown.Divider />
				<NavDropdown.Item as={Link} to="/subjectHide">
					Hide Subject
				</NavDropdown.Item>
			</NavDropdown>

			{/* <Nav.Link as={Link} to="/crudUsers">
        Users
      </Nav.Link> */}
			{/* <Nav.Link as={Link} to="/crudSubject">
        Subjects
      </Nav.Link> */}

			<Nav.Link as={Link} to="/Term">
				Semester
			</Nav.Link>
			<Nav.Link as={Link} to="/Grade">
				Grades
			</Nav.Link>
			{/* <Nav.Link as={Link} to="/crudLessonPlan">
        Lessons
      </Nav.Link> */}
			{/* <Nav.Link as={Link} to="/juniorSchool">
        Create Lessons(1-10)
      </Nav.Link>
      <Nav.Link as={Link} to="/highSchool">
        Create Lessons(11-12)
      </Nav.Link> */}
			<Link to="/logout">
				<Button variant="primary">Logout</Button>
			</Link>
		</>
	);
};

export default AdminNavComp;
