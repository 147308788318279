import { Button, Modal } from "react-bootstrap";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";

const TermEditModal = (props) => {
	console.log(props.id);
	console.log("props.id");

	const clickHandler = () => {
		props.onEditRef(
			props.id,
			props.state.term,
			props.state.startDate,
			props.state.endDate
		);
		props.onHide();
	};
	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Edit</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<label for="term">Semester:</label>
				<input
					type="text"
					placeholder="Enter term name"
					className="form-control"
					value={props.state.term}
					onChange={(e) => {
						e.preventDefault();
						props.setStateRef((prev) => {
							console.log(prev);
							return { ...prev, term: e.target.value };
						});
					}}
				/>
				<br />

				<label for="startDate">Start Date:</label>
				<NepaliDatePicker
					placeholder="Choose a date"
					inputClassName="form-control"
					className=""
					value={props.state.startDate}
					onChange={(e) => {
						// e.preventDefault();
						console.log(e);
						props.setStateRef((prev) => {
							return { ...prev, startDate: e };
						});
					}}
					//   options={{ calenderLocale: "ne", valueLocale: "en" }} //Nepali format view
					options={{ calenderLocale: "en", valueLocale: "en" }}
				/>
				<br />
				<label for="endDate">End Date:</label>
				<NepaliDatePicker
					inputClassName="form-control"
					className=""
					value={props.state.endDate}
					onChange={(e) => {
						// e.preventDefault();
						props.setStateRef((prev) => {
							return { ...prev, endDate: e };
						});
					}}
					options={{ calenderLocale: "en", valueLocale: "en" }}
				/>
			</Modal.Body>
			<Modal.Footer style={{ justifyContent: "flex-start" }}>
				<Button onClick={clickHandler} className="btn-info">
					Edit
				</Button>
				<Button onClick={props.onHide}>Close</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default TermEditModal;
