import React, { useEffect, useState } from "react";
import {
	Button,
	Col,
	Container,
	Pagination,
	Row,
	Table,
} from "react-bootstrap";

import {
	faEdit,
	faTrash,
	faPlus,
	faKey,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserAddModal from "../../../../components/Modal/User/UserAddModal";
import UserEditModal from "../../../../components/Modal/User/UserEditModal";
import DeleteModal from "../../../../components/Modal/DeleteModal";
import ResetPasswordModal from "../../../../components/Modal/ResetPasswordModal";
import { connect } from "react-redux";
import Spinner from "../../../../components/spinner/Spinner";
import {
	userAdd,
	userDelete,
	userView,
	userUpdate,
	teacherView,
	teacherAssign,
	userReset,
	userSearch,
	teacherReset,
	authResetPassword,
} from "../../../../store/actions";
import TeacherAssignModal from "../../../../components/Modal/Teacher/TeacherAssignModal";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { removeTags } from "../../../../shared/htmlTagRemover";

const CrudUser = (props) => {
	console.log("hello from crud User js");
	const [user, setuser] = useState({
		firstName: null,
		lastName: null,
		email: null,
		role: null,
	});

	const [assignTeacher, setassignTeacher] = useState({
		grade_id: null,
		subject_id: null,
	});

	const [TeacherModal, setTeacherModal] = useState({ show: false, id: null });

	const [modalEditShow, setEditModalShow] = useState({
		show: false,
		id: null,
	});

	const [modalDeleteShow, setDeleteModalShow] = useState({
		show: false,
		id: null,
	});

	const [resetPasswordModal, setResetPasswordModal] = useState({
		show: false,
		id: null,
	});

	const [query, setquery] = useState("");

	const [modalAddShow, setAddModalShow] = useState(false);

	const delRef = props.ondelete;
	const editRef = props.onUserEdit;

	const addRef = props.onUserAdd;

	const teacherRef = props.onTeacherAssign;
	const passwordResetRef = props.onPasswordReset;

	useEffect(() => {
		return (
			props.onUserView("user/details"),
			props.onTeacherView(),
			props.onteacherReset(),
			props.onuserReset()
		);
	}, []);

	const SearchHandler = (e) => {
		e.preventDefault();
		console.log(query);
		props.onSearch(query);
	};

	if (props.loading) {
		return <Spinner />;
	}

	if (props.error) {
		toast(props.error, {
			position: "top-center",
			type: "error",
			toastId: "custom-id",
		});
	}
	if (props.msg) {
		console.log(props.msg);
		toast(props.msg, {
			position: "top-center",
			type: "success",
			toastId: "custom-id",
		});
	}
	if (props.teachererror) {
		toast(props.teachererror, {
			position: "top-center",
			type: "error",
			toastId: "custom-id",
		});
	}
	if (props.teachermsg) {
		toast(props.teachermsg, {
			position: "top-center",
			type: "success",
			toastId: "custom-id",
		});
	}

	return (
		<Container>
			<ToastContainer />
			<Row>
				<Col>
					<Button variant="primary" onClick={() => setAddModalShow(true)}>
						Add User
					</Button>
				</Col>

				<Col>
					<div
						style={{ float: "right", marginBottom: "-10px" }}
						className="form-inline"
					>
						<input
							type="search"
							placeholder="  Enter a keyword"
							className="form-control"
							onChange={(e) => {
								setquery(e.target.value);
							}}
							value={query}
							style={{ margin: "0px 5px" }}
						/>
						<Button variant="dark" onClick={SearchHandler}>
							Search
						</Button>
					</div>
				</Col>
				<Table
					responsive
					striped
					bordered
					hover
					style={{ textAlign: "center", marginTop: "15px" }}
				>
					<thead>
						<tr>
							<th>First Name</th>
							<th>Last Name</th>
							<th>Email</th>
							<th>User Type</th>
							<th>Action</th>
						</tr>
					</thead>
					{props.user?.data?.length > 0 ? (
						<tbody>
							{props.user?.data.map((value, i) => {
								return (
									<tr key={value.id}>
										<td>{value.FirstName}</td>
										<td>{value.LastName}</td>
										<td>{value.email}</td>
										<td>{value.role}</td>
										<td>
											{value.role === "teacher" ? (
												<>
													<FontAwesomeIcon
														icon={faKey}
														style={{ margin: "0px 5px" }}
														onClick={() => {
															setResetPasswordModal({
																show: true,
																id: value.id,
															});
														}}
													/>
													<FontAwesomeIcon
														icon={faPlus}
														style={{ margin: "0px 5px" }}
														onClick={() =>
															setTeacherModal({ show: true, id: value.id })
														}
													/>
												</>
											) : null}
											<FontAwesomeIcon
												icon={faEdit}
												style={{ margin: "0px 5px" }}
												onClick={() => {
													setEditModalShow({
														show: true,
														id: value.id,
													});
													setuser({
														firstName: value.FirstName,
														lastName: value.LastName,
														email: value.email,
														role: value.role,
													});
												}}
											/>

											<FontAwesomeIcon
												icon={faTrash}
												onClick={() =>
													setDeleteModalShow({ show: true, id: value.id })
												}
											/>
										</td>
									</tr>
								);
							})}
						</tbody>
					) : (
						<tbody>
							<tr>
								<td colSpan="6" className="text-center">
									No User Found
								</td>
							</tr>
						</tbody>
					)}
				</Table>

				{props.user?.data?.length > 0 ? (
					<Pagination>
						<Pagination.First
							onClick={() => props.onUserView(props.user?.first_page_url)}
						/>
						{[...props.user?.links].map((value, i) => {
							return value.url === null ? null : (
								<Pagination.Item
									active={value.active}
									key={i.toString()}
									onClick={() => props.onUserView(value?.url)}
								>
									{removeTags(value.label)}
								</Pagination.Item>
							);
						})}
						<Pagination.Last
							onClick={() => props.onUserView(props.user?.last_page_url)}
						/>
					</Pagination>
				) : null}

				{modalAddShow ? (
					<UserAddModal
						show={modalAddShow}
						onAddRef={addRef}
						state={user}
						name="User"
						setStateRef={setuser}
						onHide={() => setAddModalShow(false)}
					/>
				) : null}
				{modalEditShow.show ? (
					<UserEditModal
						show={modalEditShow.show}
						id={modalEditShow.id}
						state={user}
						name="User"
						setStateRef={setuser}
						onHide={() =>
							setEditModalShow({ show: false, id: null, value: null })
						}
						onEditRef={editRef}
					/>
				) : null}
				{modalDeleteShow.show ? (
					<DeleteModal
						show={modalDeleteShow.show}
						id={modalDeleteShow.id}
						name="User"
						onHide={() => setDeleteModalShow({ show: false, id: null })}
						onDelRef={delRef}
					/>
				) : null}
				{resetPasswordModal.show ? (
					<ResetPasswordModal
						show={resetPasswordModal.show}
						id={resetPasswordModal.id}
						onPasswordResetRef={passwordResetRef}
						onHide={() => setResetPasswordModal({ show: false, id: null })}
					/>
				) : null}
				{TeacherModal.show ? (
					<TeacherAssignModal
						show={TeacherModal.show}
						id={TeacherModal.id}
						name="Teacher"
						state={assignTeacher}
						setStateRef={setassignTeacher}
						data={props.teacherData}
						onAssignRef={teacherRef}
						onHide={() => setTeacherModal({ show: false, id: null })}
					/>
				) : null}
			</Row>
		</Container>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.userR.user,
		msg: state.userR.msg,
		error: state.userR.error,
		loading: state.userR.loading,
		teacherData: state.teacherR.teacher,
		teachermsg: state.teacherR.msg,
		teachererror: state.teacherR.error,
		authmsg: state.authR.msg,
		autherror: state.authR.error,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onteacherReset: () => dispatch(teacherReset()),

		onSearch: (query) => dispatch(userSearch(query)),

		onuserReset: () => dispatch(userReset()),

		onUserAdd: (firstName, lastName, email, role) =>
			dispatch(userAdd(firstName, lastName, email, role)),

		onUserView: (url) => dispatch(userView(url)),

		onUserEdit: (id, firstName, lastName, email, role) =>
			dispatch(userUpdate(id, firstName, lastName, email, role)),

		ondelete: (user) => dispatch(userDelete(user)),

		onTeacherView: () => dispatch(teacherView()),

		onTeacherAssign: (grade_id, subject_id, user_id) =>
			dispatch(teacherAssign(grade_id, subject_id, user_id)),

		onPasswordReset: (id) => dispatch(authResetPassword(id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CrudUser);
