import { updateObject } from "../../shared/utility";

import {
  USER_ADD,
  USER_DELETE,
  USER_VIEW,
  USER_UPDATE,
  USER_FAIL,
  USER_START,
  USER_RESET,
  USER_SEARCH,
} from "../actions/actionTypes";

const initialState = {
  user: [],
  msg: null,
  error: null,
  loading: false,
};

const userReset = (state, action) => {
  return updateObject(state, {
    user: [],
    msg: null,
    error: null,
  });
};

const userAdd = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const userStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    // msg: null,
  });
};

const userFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    msg: null,
  });
};

const userView = (state, action) => {
  return updateObject(state, {
    loading: false,
    user: action.user,
  });
};

const userDelete = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const userUpdate = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const userSearch = (state, action) => {
  return updateObject(state, {
    loading: false,
    user: action.user,
  });
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_RESET:
      return userReset(state, action);
    case USER_START:
      return userStart(state, action);
    case USER_ADD:
      return userAdd(state, action);
    case USER_VIEW:
      return userView(state, action);
    case USER_DELETE:
      return userDelete(state, action);
    case USER_UPDATE:
      return userUpdate(state, action);
    case USER_FAIL:
      return userFail(state, action);
    case USER_SEARCH:
      return userSearch(state, action);
    default:
      return state;
  }
};

export default userReducer;
