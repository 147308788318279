import React, { useEffect, useState } from "react";
import { Dropdown, Container, Row, Table } from "react-bootstrap";

import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LessonPlanEditModal from "../../../components/Modal/LessonPlan/LessonPlanEditModal";
import DeleteModal from "../../../components/Modal/DeleteModal";
import LessonPlanAddModal from "../../../components/Modal/LessonPlan/LessonPlanAddModal";
import { connect } from "react-redux";
import Spinner from "../../../components/spinner/Spinner";
import {
  gradeView,
  LessonPlanAdd,
  LessonPlanDelete,
  LessonPlanView,
  LessonPlanUpdate,
  LessonPlanReset,
} from "../../../store/actions";

const CrudLessonPlan = (props) => {
  console.log("Hello from crud lessonplan js");

  const [grade, setgrade] = useState({ value: null, show: false });

  let [lessonPlan, setlessonPlan] = useState({
    subjectclass_id: null,
    term_id: null,
    date: null,
    week: null,
    day: null,
    lesson: null,
    topic: null,
    classwork: null,
    homework: null,
    submission_date: null,
    video_url: null,
    objective: null,
  });

  const [modalEditShow, setEditModalShow] = useState({
    show: false,
    id: null,
  });

  const [modalDeleteShow, setDeleteModalShow] = useState({
    show: false,
    id: null,
  });

  const [modalAddShow, setAddModalShow] = useState(false);

  const delRef = props.ondelete;
  const editRef = props.onLessonPlanEdit;

  // const addRef = props.onLessonPlanAdd;

  useEffect(() => {
    return props.onGradeView(), props.onLessonPlanReset();
  }, []);

  if (props.loading) {
    return <Spinner />;
  }

  let errorMsg = null;
  if (props.error) {
    errorMsg = <p className="alert alert-danger">{props.error}</p>;
  }

  const viewHandler = (event) => {
    console.log("viewHandler");
    console.log(event.target.value);
    event.preventDefault();
    setgrade((prev) => {
      return { ...prev, show: true };
    });
    props.onlessonPlanView(event.target.value);
  };

  return (
    <Container>
      <Row>
        <form className="form-inline col-md-4">
          <div className="form-group">
            <select className="form-select" onChange={viewHandler}>
              <option selected>Select Grade</option>
              {props.grade.map((value) => {
                return (
                  <option
                    value={value.id}
                    key={value.id}
                    selected={grade.value == value.id}
                  >
                    {value.Grade}
                  </option>
                );
              })}
            </select>
            {/* <Dropdown>
              <Dropdown.Toggle variant="info" id="dropdown-basic">
                Select Grade
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {props.grade.map((value) => {
                  return (
                    <Dropdown.Item
                      onClick={(e) => viewHandler(e, value.id)}
                      key={value.id}
                    >
                      {value.Grade}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown> */}
          </div>
        </form>
        {errorMsg}
        <Table
          responsive
          striped
          bordered
          hover
          style={{ textAlign: "center" }}
        >
          <thead>
            <tr>
              <th style={{ width: "15%" }}>S.N.</th>
              <th>Subject</th>
              <th>Grade</th>
              <th>Teacher</th>
              <th>Action</th>
            </tr>
          </thead>
          {grade.show ? (
            <tbody>
              {/* {props.lessonPlan.map((value, i) => {
                return (
                  <tr key={value.id}>
                    <td>{value.id}</td>
                    <td>{value.term}</td>
                    <td>{value.StartDate}</td>
                    <td>{value.EndDate}</td>
                    <td>
                      <FontAwesomeIcon
                        icon={faEdit}
                        style={{ margin: "0px 5px" }}
                        onClick={() => {
                          setEditModalShow({
                            show: true,
                            id: value.id,
                          });
                          setterm({
                            term: value.term,
                            startDate: value.StartDate,
                            endDate: value.EndDate,
                          });
                        }}
                      />

                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() =>
                          setDeleteModalShow({ show: true, id: value.id })
                        }
                      />
                    </td>
                  </tr>
                );
              })} */}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan="6" className="text-center">
                  Please Select Grade
                </td>
              </tr>
            </tbody>
          )}
        </Table>

        {modalAddShow ? (
          <LessonPlanAddModal
            show={modalAddShow}
            // onAddRef={addRef}
            state={lessonPlan}
            name="Lesson Plan"
            setStateRef={setlessonPlan}
            onHide={() => setAddModalShow(false)}
          />
        ) : null}
        {modalEditShow.show ? (
          <LessonPlanEditModal
            show={modalEditShow.show}
            id={modalEditShow.id}
            state={lessonPlan}
            name="Lesson Plan"
            setStateRef={setlessonPlan}
            onHide={() =>
              setEditModalShow({ show: false, id: null, value: null })
            }
            onEditRef={editRef}
          />
        ) : null}
        {modalDeleteShow.show ? (
          <DeleteModal
            show={modalDeleteShow.show}
            id={modalDeleteShow.id}
            name="Lesson Plan"
            onHide={() => setDeleteModalShow({ show: false, id: null })}
            onDelRef={delRef}
          />
        ) : null}
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    grade: state.gradeR.grade,
    msg: state.lessonPlanR.msg,
    error: state.lessonPlanR.error,
    loading: state.lessonPlanR.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLessonPlanReset: () => dispatch(LessonPlanReset()),

    onGradeView: () => dispatch(gradeView()),

    onlessonPlanAdd: (term, startDate, endDate) =>
      dispatch(LessonPlanAdd(term, startDate, endDate)),

    onlessonPlanView: (id) => dispatch(LessonPlanView(id)),

    onlessonPlanEdit: (id, term, startDate, endDate) =>
      dispatch(LessonPlanUpdate(id, term, startDate, endDate)),

    ondelete: (term) => dispatch(LessonPlanDelete(term)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CrudLessonPlan);
