import React, { useState } from "react";
import { Col, Container, Row, Button, Table } from "react-bootstrap";
import classes from "./lessonPlan.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowLeft,
	faArrowRight,
	faEdit,
	faPlayCircle,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { displayLesson, LessonPlanDelete } from "../../../store/actions";
import { connect } from "react-redux";
import Spinner from "../../../components/spinner/Spinner";
import DeleteModal from "../../../components/Modal/DeleteModal";
import EditLessonPlanModal from "../../../components/Modal/LessonPlan/LessonPlanEditModal";

const LessonPlan = (props) => {
	const subject_id = props.match.params.subject;
	const term_id = props.match.params.term;
	const week = props.match.params.week;
	const [deleteLessonPlanModal, setDeleteLessonPlanModal] = useState({
		show: false,
		id: null,
		subject_id: null,
		term_id: null,
		week: null,
	});

	const [editLessonPlanModal, setEditLessonPlanModal] = useState({
		show: false,
		id: null,
		subject_id: null,
		term_id: null,
		week: null,
		data: null,
	});

	const delRef = props.onDeleteLessonPlan;
	const editRef = props.onEditLessonPlan;

	let prev = null;
	let next = null;

	let teacher = null;
	let admin = null;
	console.log(props.isAuthenticated);
	if (props.isAuthenticated) {
		teacher = JSON.parse(props.user).role === "teacher";
		admin = JSON.parse(props.user).role === "admin";
	}

	if (props.lessonPlan.length === 0) {
		props.onLessonDisplay(
			props.match.params.subject,
			props.match.params.term,
			props.match.params.week
		);
	}

	const prevHandler = () => {
		props.onLessonDisplay(
			props.lessonPlan.first.subjectclass_id,
			props.lessonPlan.first.term_id,
			props.lessonPlan.first.Week - 1
		);
	};
	const nextHandler = () => {
		props.onLessonDisplay(
			props.lessonPlan.first.subjectclass_id,
			props.lessonPlan.first.term_id,
			props.lessonPlan.first.Week + 1
		);
		// props.match.params.week = Number(props.match.params.week) + 1;
	};
	if (props.loading) {
		return <Spinner />;
	}
	prev =
		1 < props?.lessonPlan?.first?.Week ? (
			<Button
				variant="light"
				className={classes.previous}
				onClick={prevHandler}
			>
				<FontAwesomeIcon icon={faArrowLeft} style={{ margin: "0px 5px" }} />
				Previous Week
			</Button>
		) : null;

	next =
		props?.lessonPlan?.week > props?.lessonPlan?.first?.Week ? (
			<Button
				variant="light"
				className={classes.previous}
				style={{ float: "right" }}
				onClick={nextHandler}
			>
				Next Week
				<FontAwesomeIcon icon={faArrowRight} style={{ margin: "0px 5px" }} />
			</Button>
		) : null;

	return (
		<Container fluid id={classes.lesson}>
			<Row className="row justify-content-center">
				<Col md={10}>
					<ul className={classes.collection} style={{ padding: "5px" }}>
						<li>
							<Row>
								<Col md={2}>{prev}</Col>
								<Col md={8} className={classes.center}>
									<div>
										<b> Subject - </b>
										<span className={classes.tealText}>
											{
												props?.lessonPlan?.first?.subject_class_priority
													?.subject?.Subject
											}
										</span>
										|<b> Term - </b>
										<span className={classes.tealText}>
											{props?.lessonPlan?.first?.term?.term}
										</span>
										|<b> Week - </b>
										<span className={classes.tealText}>
											{props?.lessonPlan?.first?.Week}
										</span>
									</div>
								</Col>
								<Col md={2}>{next}</Col>
							</Row>
						</li>
					</ul>

					<div className={classes.scrolling}>
						<Table>
							<thead>
								<tr>
									<th>Day</th>
									<th>Date</th>
									<th>Lesson</th>
									<th>Topic</th>
									<th>Objective</th>
									{props?.lessonPlan?.first?.subject_class_priority?.grade
										?.Grade < 11 ? (
										<>
											<th>Classwork</th>
											<th>Homework</th>
										</>
									) : (
										<>
											<th>Assignment</th>
											<th>Submission Date</th>
										</>
									)}
									<th>Flipped Class Video</th>
									{teacher || admin ? <th>Action</th> : null}
								</tr>
							</thead>
							<tbody>
								{props?.lessonPlan.length === 0 ? (
									<div />
								) : (
									[...props?.lessonPlan?.lesson]?.map((val) => {
										console.log(val.Topic == null);
										let condition =
											props?.lessonPlan?.first?.subject_class_priority?.grade
												?.Grade < 11
												? val.Classwork == null && val.Homework == null
												: val.Assignment == null;

										if (
											val.Topic == null &&
											val.Objective == null &&
											condition
										) {
											return (
												<tr className={classes.holiday} key={val.id}>
													<td>{val.Day}</td>

													<td width="10%" className="date">
														{val.Date}
													</td>
													{val.Lesson != null ? (
														<td colSpan="6" className="text-center">
															{val.Lesson}
														</td>
													) : (
														<td colSpan="6" className="text-center">
															No Class
														</td>
													)}
													<td className="d-none"> {val.Topic} </td>
													<td className="d-none"> {val.Objective} </td>
													<td className="d-none"> {val.Classwork} </td>
													<td className="d-none"> {val.Homework}</td>
													<td className="d-none"> {val.Week} </td>
													<td className="d-none"> {val.SubmissionDate} </td>
													<td className="d-none"> {val.Time} </td>
													<td className="d-none"> {val.VideoUrl} </td>
													<td className="d-none"> {val.id} </td>

													{admin ||
													JSON.parse(props.user)?.id ==
														props.lessonPlan?.first?.subject_class_priority
															?.user_id ? (
														<>
															<td>
																<FontAwesomeIcon
																	icon={faEdit}
																	style={{ margin: "0px 5px" }}
																	onClick={() =>
																		setEditLessonPlanModal({
																			show: true,
																			id: val.id,
																			subject_id: subject_id,
																			term_id: term_id,
																			week: week,
																			data: {
																				day: val.day,
																				date: val.date,
																				lesson: val.Lesson,
																				topic: val.Topic,
																				objective: val.Objective,
																				classwork: val?.Classwork,
																				homework: val?.Homework,
																				assignment: val?.Assignment,
																				time: val?.Time,
																				flippedClass: val.VideoUrl,
																				submissionDate: val?.SubmissionDate,
																			},
																		})
																	}
																/>
																<FontAwesomeIcon
																	icon={faTrash}
																	style={{ margin: "0px 5px" }}
																	onClick={() =>
																		setDeleteLessonPlanModal({
																			show: true,
																			id: val.id,
																			subject_id: subject_id,
																			term_id: term_id,
																			week: week,
																		})
																	}
																/>
															</td>
														</>
													) : teacher ||
													  JSON.parse(props.user)?.id ==
															props.lessonPlan?.first?.subject_class_priority
																?.user_id ? (
														<>
															<td>
																<FontAwesomeIcon
																	icon={faEdit}
																	style={{ margin: "0px 5px" }}
																	onClick={() =>
																		setEditLessonPlanModal({
																			show: true,
																			id: val.id,
																			subject_id: subject_id,
																			term_id: term_id,
																			week: week,
																			data: {
																				day: val.Day,
																				date: val.Date,
																				lesson: val.Lesson,
																				topic: val.Topic,
																				objective: val.Objective,
																				classwork: val?.Classwork,
																				homework: val?.Homework,
																				assignment: val?.Assignment,
																				time: val?.Time,
																				flippedClass: val.VideoUrl,
																				submissionDate: val?.SubmissionDate,
																			},
																		})
																	}
																/>
															</td>
														</>
													) : null}
												</tr>
											);
										} else {
											return (
												<tr key={val.id}>
													<td>{val.Day}</td>
													<td width="10%" className="date">
														{val.Date}
													</td>
													<td width="12%">{val.Lesson}</td>

													<td> {val.Topic} </td>
													<td> {val.Objective} </td>
													{props?.lessonPlan?.first?.subject_class_priority
														?.grade?.Grade < 11 ? (
														<>
															<td> {val.Classwork} </td>
															<td> {val.Homework}</td>
														</>
													) : (
														<>
															<td> {val.Assignment} </td>
															<td> {val.SubmissionDate}</td>
														</>
													)}
													<td className="d-none"> {val.Week} </td>
													<td className="d-none">{val.SubmissionDate}</td>
													<td className="d-none"> {val.Time} </td>
													<td className="d-none"> {val.VideoUrl} </td>
													<td className="d-none"> {val.id} </td>

													<td>
														{val?.VideoUrl != null ? (
															<>
																<a href={val.VideoUrl} target="_blank">
																	<FontAwesomeIcon
																		icon={faPlayCircle}
																		style={{
																			margin: "0px 5px",
																			fontSize: "35px",
																		}}
																	/>
																</a>
															</>
														) : null}
													</td>
													{admin ||
													JSON.parse(props.user)?.id ==
														props.lessonPlan?.first?.subject_class_priority
															?.user_id ? (
														<>
															<td>
																<a href="#">
																	<FontAwesomeIcon
																		icon={faEdit}
																		style={{ margin: "0px 5px" }}
																		onClick={() =>
																			setEditLessonPlanModal({
																				show: true,
																				id: val.id,
																				subject_id: subject_id,
																				term_id: term_id,
																				week: week,
																				data: {
																					day: val.Day,
																					date: val.Date,
																					lesson: val.Lesson,
																					topic: val.Topic,
																					objective: val.Objective,
																					classwork: val?.Classwork,
																					homework: val?.Homework,
																					assignment: val?.Assignment,
																					time: val?.Time,
																					flippedClass: val.VideoUrl,
																					submissionDate: val?.SubmissionDate,
																				},
																			})
																		}
																	/>
																</a>
																<a href="#">
																	<FontAwesomeIcon
																		icon={faTrash}
																		style={{ margin: "0px 5px" }}
																		onClick={() =>
																			setDeleteLessonPlanModal({
																				show: true,
																				id: val.id,
																				subject_id: subject_id,
																				term_id: term_id,
																				week: week,
																			})
																		}
																	/>
																</a>
															</td>
														</>
													) : teacher ||
													  JSON.parse(props.user)?.id ==
															props.lessonPlan?.first?.subject_class_priority
																?.user_id ? (
														<>
															<td>
																<FontAwesomeIcon
																	icon={faEdit}
																	style={{ margin: "0px 5px" }}
																	onClick={() =>
																		setEditLessonPlanModal({
																			show: true,
																			id: val.id,
																			subject_id: subject_id,
																			term_id: term_id,
																			week: week,
																			data: {
																				day: val.Day,
																				date: val.Date,
																				lesson: val.Lesson,
																				topic: val.Topic,
																				objective: val.Objective,
																				classwork: val?.Classwork,
																				homework: val?.Homework,
																				assignment: val?.Assignment,
																				time: val?.Time,
																				flippedClass: val.VideoUrl,
																				submissionDate: val?.SubmissionDate,
																			},
																		})
																	}
																/>
															</td>
														</>
													) : null}
												</tr>
											);
										}
									})
								)}
							</tbody>
						</Table>
					</div>
					<div className="alert alert-warning" style={{ fontSize: "large" }}>
						<b>DISCLAIMER:</b> On this weekly planning, the days taught or
						lessons are subject to change but they will be covered at the
						earliest.
					</div>
				</Col>
				{deleteLessonPlanModal.show ? (
					<DeleteModal
						show={deleteLessonPlanModal.show}
						id={deleteLessonPlanModal.id}
						subject_id={deleteLessonPlanModal.subject_id}
						term_id={deleteLessonPlanModal.term_id}
						week={deleteLessonPlanModal.week}
						name="LessonPlan"
						onDelRef={delRef}
						onHide={() => setDeleteLessonPlanModal({ show: false, id: null })}
					/>
				) : null}
				{editLessonPlanModal.show ? (
					<EditLessonPlanModal
						show={editLessonPlanModal.show}
						id={editLessonPlanModal.id}
						subject_id={editLessonPlanModal.subject_id}
						term_id={editLessonPlanModal.term_id}
						week={editLessonPlanModal.week}
						data={editLessonPlanModal.data}
						name="LessonPlan"
						onDelRef={delRef}
						onHide={() => setEditLessonPlanModal({ show: false, id: null })}
					/>
				) : null}
			</Row>
		</Container>
	);
};

const mapStateToProps = (state) => {
	return {
		lessonPlan: state.subjectClassPriorityR.lessonPlan,
		msg: state.subjectClassPriorityR.msg,
		error: state.subjectClassPriorityR.error,
		loading: state.subjectClassPriorityR.loading,

		isAuthenticated: state.authR.token !== null,
		user: state.authR?.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onLessonDisplay: (subject_id, term_id, week) => {
			dispatch(displayLesson(subject_id, term_id, week));
		},
		onDeleteLessonPlan: (id, subject_id, term_id, week) =>
			dispatch(LessonPlanDelete(id, subject_id, term_id, week)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LessonPlan);
