import React from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import classes from "./viewCard.module.css";

const ViewCards = (props) => {
	const temp = "/grade/";
	return (
		<Col md={props.number}>
			<Card>
				<Link
					to={
						props.url === "lower"
							? temp + "lower"
							: // : props.url == "lowergrade"
							// ? temp + "lower"
							// : props.url == "lowerhigh"
							// ? temp + "lowerhigh"
							props.url === "highSchool"
							? temp + "highSchool"
							: temp + "middleSchool"
					}
					className=" d-flex flex-column align-items-center"
				>
					<Card.Img variant="top" src={props.image} className="w-75 h-75" />
					<Card.Body>
						<Row style={{ justifyContent: "center" }}>
							<Button variant="light" className={classes.submitbtn}>
								View Subjects
							</Button>
						</Row>
					</Card.Body>
				</Link>
			</Card>
		</Col>
	);
};

export default ViewCards;
