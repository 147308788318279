import React, { useEffect } from "react";
import "./App.module.css";

import NavBar from "../components/nav";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { authCheckState } from "../store/actions/index";
import axios from "axios";
import Footer from "../components/footer";
import { isMobile } from "react-device-detect";
import classes from "../containers/App.module.css";
import { Container } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import SiteUpdated from "./siteUpdated";

axios.interceptors.request.use(
	(request) => {
		console.log(request);
		//Edit request config
		return request;
	},
	(error) => {
		console.log("error");
		console.log(error);
		//Edit response config
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	(response) => {
		console.log(response);
		return response;
	},
	(error) => {
		console.log("error from response");
		return Promise.reject(error.response);
	}
);

function App(props) {
	// axios.defaults.baseURL = "https://lesson-plan-api.herokuapp.com/api";
	// axios.defaults.baseURL = "http://127.0.0.1:8000/api";
	axios.defaults.baseURL = "https://lessonplanapi.dwit.edu.np/api";
	axios.defaults.headers.common["Authorization"] = `Bearer ${props.token}`;
	axios.defaults.headers.post["Content-Type"] = "application/json"; //default

	useEffect(() => props.onCheckHandler(), []);
	// console.log(props.authCheckLoading);
	return props.authCheckLoading ? null : (
		<Router>
			<SiteUpdated></SiteUpdated>
			{/* <NavBar className="flex-grow-1" />
			{isMobile ? (
				<Container fluid style={{ backgroundColor: "black" }}>
					<Footer />
					<ToastContainer />
				</Container>
			) : (
				<Container
					fluid
					className={classes.footer}
					style={{ backgroundColor: "black" }}
				>
					<Footer />
					<ToastContainer />
				</Container>
			)} */}
		</Router>
	);
}
const mapStateToProps = (state) => {
	return {
		token: state.authR.token,
		authCheckLoading: state.authR.authCheckLoading,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		onCheckHandler: () => dispatch(authCheckState()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
