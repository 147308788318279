import { updateObject } from "../../shared/utility";

import {
  GRADE_ADD,
  GRADE_DELETE,
  GRADE_VIEW,
  GRADE_UPDATE,
  GRADE_FAIL,
  GRADE_START,
  GRADE_RESET,
  GRADE_PAGINATE,
} from "../actions/actionTypes";

const initialState = {
  grade: [],
  gradePaginate: [],
  msg: null,
  error: null,
  loading: false,
};

const gradeAdd = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const gradeReset = (state, action) => {
  return updateObject(state, {
    grade: [],
    gradePaginate: [],
    msg: null,
    error: null,
  });
};

const gradeStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const gradeFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    msg: null,
  });
};

const gradeView = (state, action) => {
  return updateObject(state, {
    loading: false,
    grade: action.grade,
  });
};

const gradeViewPaginate = (state, action) => {
  return updateObject(state, {
    loading: false,
    gradePaginate: action.gradePaginate,
  });
};

const gradeDelete = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const gradeUpdate = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const gradeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GRADE_START:
      return gradeStart(state, action);
    case GRADE_ADD:
      return gradeAdd(state, action);
    case GRADE_VIEW:
      return gradeView(state, action);
    case GRADE_DELETE:
      return gradeDelete(state, action);
    case GRADE_UPDATE:
      return gradeUpdate(state, action);
    case GRADE_FAIL:
      return gradeFail(state, action);
    case GRADE_RESET:
      return gradeReset(state, action);
    case GRADE_PAGINATE:
      return gradeViewPaginate(state, action);
    default:
      return state;
  }
};

export default gradeReducer;
