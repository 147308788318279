import React, { useState } from "react";

import { Card, Col, Container, Row } from "react-bootstrap";
import { auth } from "../../store/actions/index";
import { connect } from "react-redux";

import Spinner from "../../components/spinner/Spinner";
import { Redirect } from "react-router";

const Login = (props) => {
	const [email, setemail] = useState("");

	const [password, setpassword] = useState("");

	// console.log(props);

	// const fetchData = async () => {
	//   let APIdata;
	//   try {
	//     APIdata = await axios
	//       .post("/login", {
	//         email: email,
	//         password: password,
	//       })
	//       .catch((error) => {
	//         console.log(error);
	//       });

	//     const expireDate = new Date(new Date().getTime() + 60 * 1000);
	//     localStorage.setItem("token", APIdata.data.data.access_token.token);
	//     // localStorage.setItem("user", APIdata.data.localId);
	//     localStorage.setItem("expireTime", expireDate);
	//   } catch (e) {}

	//   // data=await fetch('/login',{
	//   //   method: 'POST',
	//   //   headers: {
	//   //     Accept: 'application/json',
	//   //     'Content-Type': 'application/json'
	//   //   },
	//   //   body: JSON.stringify({
	//   //     email: email,
	//   //     password: password
	//   //   })
	//   // });

	//   // console.log("data is ", APIdata.data);
	//   // props.history.push("/");
	// };

	const submitHandler = (event) => {
		event.preventDefault();
		// console.log("submit handler");
		props.onAuth(email, password);
	};

	if (props.loading) {
		console.log("loading");
		return <Spinner />;
	}

	let errorMsg = null;

	if (props.error) {
		errorMsg = <p className="alert alert-danger">{props.error}</p>;
	}

	let authRedirect = null;
	if (props.isAuthenticated) {
		authRedirect = <Redirect to="/dashboard" />;
	}

	return (
		<Container>
			<Row className="justify-content-center">
				<Col md={8}>
					<Card>
						<Card.Header>Login</Card.Header>
						<Card.Body>
							{/* <form onSubmit={(e) => e.preventDefault()}> */}
							{authRedirect}
							{errorMsg}
							<form onSubmit={submitHandler}>
								<div className="form-group row">
									<label
										for="email"
										className="col-md-4 col-form-label text-md-right"
									>
										E-Mail Address :{" "}
									</label>
									<div className="col-md-6">
										<input
											placeholder="Username"
											type="email"
											value={email}
											onChange={(e) => {
												setemail(e.target.value);
											}}
										/>
									</div>
								</div>
								<div className="form-group row">
									<label
										for="email"
										className="col-md-4 col-form-label text-md-right"
									>
										Password :{" "}
									</label>
									<div className="col-md-6">
										<input
											placeholder="Password"
											type="password"
											value={password}
											onChange={(e) => {
												setpassword(e.target.value);
											}}
										/>
									</div>
								</div>
								<div className="form-group row mb-0">
									<div className="col-md-8 offset-md-4">
										<button
											className="btn btn-primary"
											style={{
												backgroundColor: "#26a69a",
												borderColor: "white",
												textTransform: "uppercase",
											}}
										>
											Login
										</button>
									</div>
								</div>
							</form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.authR.loading,
		error: state.authR.error,
		isAuthenticated: state.authR.token !== null,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		onAuth: (email, password) => dispatch(auth(email, password)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

// export default Login;
