import axios from "axios";

import {
  TERM_ADD,
  TERM_VIEW,
  TERM_DELETE,
  TERM_UPDATE,
  TERM_START,
  TERM_FAIL,
  TERM_RESET,
} from "./actionTypes";

export const termCreate = (msg) => {
  return {
    type: TERM_ADD,
    msg: msg,
  };
};

export const handletermReset = () => {
  return {
    type: TERM_RESET,
  };
};

export const termStart = () => {
  return {
    type: TERM_START,
  };
};

export const termFail = (msg) => {
  return {
    type: TERM_FAIL,
    error: msg,
  };
};
export const term = (term) => {
  return {
    type: TERM_VIEW,
    term: term,
  };
};

export const termDestroy = (msg) => {
  return {
    type: TERM_DELETE,
    msg: msg,
  };
};

export const termPatch = (msg) => {
  return {
    type: TERM_UPDATE,
    msg: msg,
  };
};

export const termReset = () => {
  return (dispatch) => {
    dispatch(handletermReset());
  };
};

export const termAdd = (term, startDate, endDate) => {
  console.log("Hello I'm from termA.js");
  console.log(term);

  return async (dispatch) => {
    // dispatch(termStart());
    await axios
      .post("/term/store", {
        term: term,
        StartDate: startDate,
        EndDate: endDate,
      })
      .then((response) => {
        console.log(response);
        response.data?.response?.message
          ? dispatch(termCreate(response.data?.response?.message))
          : response.data?.response
          ? dispatch(termCreate(response.data?.response))
          : dispatch(termCreate("Network is not Established"));

        if (response.data.isSuccess == false) {
          dispatch(termFail(response.data?.response?.message));
        }
      })
      .catch((err) => {
        console.log(err);
        err.data?.message
          ? dispatch(termFail(err.data?.message))
          : err.data?.response
          ? dispatch(termFail(err.data?.response))
          : dispatch(termFail("Network is not Established"));
      });
    dispatch(termView());
  };
};

export const termDelete = (term) => {
  return async (dispatch) => {
    // dispatch(termStart());
    await axios
      .post("term/" + term, { _method: "DELETE" })
      .then((response) => {
        response.data?.response?.message
          ? dispatch(termDestroy(response.data?.response?.message))
          : response.data?.response
          ? dispatch(termDestroy(response.data?.response))
          : dispatch(termDestroy("Something went wrong! Please try again."));
      })
      .catch((err) => {
        console.log(err);
        err.data?.message
          ? dispatch(termFail(err.data?.message))
          : err.data?.response
          ? dispatch(termFail(err.data?.response))
          : dispatch(termFail("Network is not Established"));
      });
    dispatch(termView());
  };
};

export const termUpdate = (id, term, startDate, endDate) => {
  return async (dispatch) => {
    // dispatch(termStart());
    await axios
      .post("term/" + id, {
        term: term,
        StartDate: startDate,
        EndDate: endDate,
        _method: "PUT",
      })
      .then((response) => {
        response.data?.response?.message
          ? dispatch(termPatch(response.data?.response?.message))
          : response.data?.response
          ? dispatch(termPatch(response.data?.response))
          : dispatch(termPatch("Something went wrong! Please try again."));
      })
      .catch((err) => {
        console.log(err);
        err.data.message
          ? dispatch(termFail(err.data?.message))
          : err.data?.response
          ? dispatch(termFail(err.data?.response))
          : dispatch(termFail("Network is not Established"));
      });
    dispatch(termView());
  };
};

export const termView = () => {
  return async (dispatch) => {
    dispatch(termStart());
    await axios
      .get("term/details")
      .then((response) => {
        console.log(response);
        response.data.response
          ? dispatch(term(response.data.response))
          : dispatch(term("Network is not Established"));
      })
      .catch((err) => {
        err.data?.message
          ? dispatch(termFail(err.data?.message))
          : err.data?.response
          ? dispatch(termFail(err.data?.response))
          : dispatch(termFail("Network is not Established"));
      });
  };
};
