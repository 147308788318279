import axios from "axios";

import {
	GRADE_ADD,
	GRADE_VIEW,
	GRADE_DELETE,
	GRADE_UPDATE,
	GRADE_START,
	GRADE_FAIL,
	GRADE_RESET,
	GRADE_PAGINATE,
} from "./actionTypes";

export const gradeCreate = (msg) => {
	return {
		type: GRADE_ADD,
		msg: msg,
	};
};

export const gradeStart = () => {
	return {
		type: GRADE_START,
	};
};

export const gradeFail = (msg) => {
	return {
		type: GRADE_FAIL,
		error: msg,
	};
};

export const grade = (grade) => {
	return {
		type: GRADE_VIEW,
		grade: grade,
	};
};

export const handlegradePaginate = (gradePaginate) => {
	return {
		type: GRADE_PAGINATE,
		gradePaginate: gradePaginate,
	};
};

export const gradeDestroy = (msg) => {
	return {
		type: GRADE_DELETE,
		msg: msg,
	};
};

export const gradePatch = (msg) => {
	return {
		type: GRADE_UPDATE,
		msg: msg,
	};
};
export const handlegradeReset = () => {
	return {
		type: GRADE_RESET,
	};
};

export const gradeReset = () => {
	return (dispatch) => {
		dispatch(handlegradeReset());
	};
};

export const gradeAdd = (grade) => {
	console.log("Hello I'm from grade.js");
	console.log(grade);

	return async (dispatch) => {
		await axios
			.post("/grade/store", {
				Grade: grade,
			})
			.then((response) => {
				console.log(response);
				response.data.response?.message
					? dispatch(gradeCreate(response.data?.response?.message))
					: dispatch(gradeCreate("Network is not Established"));

				if (response.data.response.success == false) {
					dispatch(gradeFail(response.data.response?.message));
				}
			})
			.catch((err) => {
				err.data?.message
					? dispatch(gradeFail(err.data?.message))
					: err.data?.response
					? dispatch(gradeFail(err.data?.response?.message))
					: dispatch(gradeFail("Network is not Established"));
			});
		dispatch(gradeViewPaginate());
	};
};

export const gradeDelete = (grade) => {
	return async (dispatch) => {
		await axios
			.post("grade/" + grade, { _method: "DELETE" })
			.then((response) => {
				response.data?.response
					? dispatch(gradeDestroy(response.data?.response))
					: response.data?.response?.message
					? dispatch(gradeDestroy(response.data?.response?.message))
					: dispatch(gradeDestroy("Something went wrong! Please try again."));
			})
			.catch((err) => {
				console.log(err);
				err.data?.message
					? dispatch(gradeFail(err.data?.message))
					: err.data?.response
					? dispatch(gradeFail(err.data?.response?.message))
					: dispatch(gradeFail("Network is not Established"));
			});
		dispatch(gradeViewPaginate());
	};
};

export const gradeUpdate = (id, grade) => {
	return async (dispatch) => {
		await axios
			.post("grade/" + id, {
				Grade: grade,
				_method: "PUT",
			})
			.then((response) => {
				response.data.response?.message
					? dispatch(gradePatch(response.data.response?.message))
					: response.data?.response
					? dispatch(gradePatch(response.data?.response))
					: dispatch(gradePatch("Something went wrong! Please try again."));
			})
			.catch((err) => {
				console.log(err);
				err.data?.message
					? dispatch(gradeFail(err.data?.message))
					: dispatch(gradeFail("Network is not Established"));
			});
		dispatch(gradeViewPaginate());
	};
};

export const gradeView = () => {
	return async (dispatch) => {
		dispatch(gradeStart());
		await axios
			.get("grade/details")
			.then((response) => {
				console.log(response);
				response.data?.response
					? dispatch(grade(response.data?.response))
					: dispatch(grade("Network is not Established"));

				if (response.data?.response?.success == false) {
					dispatch(gradeFail(response.data?.response?.message));
				}
			})
			.catch((err) => {
				err.data?.message
					? dispatch(gradeFail(err.data?.message))
					: dispatch(gradeFail("Network is not Established"));
			});
	};
};

export const gradeViewPaginate = (url = "grade/paginate") => {
	return async (dispatch) => {
		dispatch(gradeStart());
		await axios
			.get(url)
			.then((response) => {
				console.log(response);
				response.data?.response
					? dispatch(handlegradePaginate(response.data?.response))
					: dispatch(handlegradePaginate("Network is not Established"));

				if (response.data?.response?.success == false) {
					dispatch(gradeFail(response.data?.response?.message));
				}
			})
			.catch((err) => {
				err.data?.message
					? dispatch(gradeFail(err.data?.message))
					: dispatch(gradeFail("Network is not Established"));
			});
	};
};
