import axios from "axios";
import React, { useEffect, useState } from "react";
import { Row, Col, Container, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import classes from "./grades.module.css";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "../../../components/spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { SubjectClassResetState } from "../../../store/actions";
import { connect } from "react-redux";

const Grade = (props) => {
	const level = props.match.params.level;
	const [grades, setgrades] = useState("");
	const [loading, setloading] = useState(false);
	const [error, setError] = useState(" ");

	console.log("Hello from grades.js");
	let noOfGrades;
	const fetchData = async () => {
		setloading(true);
		try {
			noOfGrades = await axios
				.get(`/grade/getLevel/${level}`)
				.catch((error) => {
					console.log(error);
					setError(error.data?.response);
					setloading(false);
				});
			console.log("noOfGrades", noOfGrades);
			setgrades(noOfGrades.data.response.grade);
			setloading(false);
		} catch (e) {}
	};

	useEffect(() => {
		return fetchData(), props.onResetState();
	}, []);

	if (loading) {
		return <Spinner />;
	}

	if (error !== " ") {
		toast(error, {
			position: "top-center",
			type: "error",
			toastId: "custom-id",
		});
	}

	// console.log("data");
	return (
		<>
			<Container fluid>
				<Row className="justify-content-md-center">
					<Col md="10">
						<div className={classes.collection}>
							{[...grades]?.map((i, index) => {
								return (
									<ListGroup key={index.toString()}>
										<Link to={"/subject/" + i?.Grade}>
											<ListGroup.Item className={classes.item}>
												<h4 className={classes.text}>
													<FontAwesomeIcon
														icon={faChevronCircleRight}
														style={{ margin: "0px 5px" }}
													/>
													Grades {i?.Grade}
												</h4>
											</ListGroup.Item>
										</Link>
									</ListGroup>
								);
							})}
						</div>
						{/* <div>{[...Array(4)]?.map((i) => i?.Grade)}</div> */}
					</Col>
				</Row>
			</Container>
			<ToastContainer />
		</>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		onResetState: () => dispatch(SubjectClassResetState()),
	};
};

export default connect(null, mapDispatchToProps)(Grade);
