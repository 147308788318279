import React from "react";
import navImg from "../assets/Images/sifalSchool.png";
import maintendanceImg from "../assets/Images/site_maintenance.jpg";
function SiteUpdated(){

    return (
        <>
            <div className="container-fluid shadow" >
                <div className="d-flex flex-row justify-content-center" style={{height: "20vh"}}>
                    <img src={navImg} alt="logo"/>
                </div>
            </div>
            <div className="container-fluid">
                <div className="d-flex flex-column justify-content-center align-items-center" style={{height: "80vh"}}>
                    <img src={maintendanceImg} alt="maintenance_image" style={{height:"300px"}}/>
                    <h1 className="mt-3">The site is under maintenance.</h1>
                </div>
            </div>
        </>
    );
}

export default SiteUpdated;