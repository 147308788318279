import { updateObject } from "../../shared/utility";

import {
  SUBJECT_CLASS_PRIORITY_START,
  SUBJECT_CLASS_PRIORITY_FAIL,
  SUBJECT_CLASS_PRIORITY_RESET,
  SUBJECT_CLASS_PRIORITY_VIEW,
  SUBJECT_CLASS_PRIORITY_UPDATE,
  SUBJECT_CLASS_PRIORITY_DELETE,
  SUBJECT_CLASS_PRIORITY_HIDE,
  SUBJECT_CLASS_PRIORITY_GET_WEEK,
  SUBJECT_CLASS_PRIORITY_DISPLAY_LESSON,
} from "../actions/actionTypes";

const initialState = {
  subjectClassPriority: [],
  lessonPlan: [],
  week: null,
  msg: null,
  error: null,
  loading: false,
};

const resetState = (state, action) => {
  return updateObject(state, {
    lessonPlan: [],
    week: null,
    msg: null,
    error: null,
  });
};

const subjectClassPriorityStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    // msg: null,
  });
};

const subjectClassPriorityFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    subjectClassPriority: [],
    msg: null,
  });
};

const subjectClassPriorityView = (state, action) => {
  return updateObject(state, {
    loading: false,
    subjectClassPriority: action.subjectClassPriority,
  });
};

const subjectClassPriorityDelete = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const subjectClassPriorityUpdate = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const getWeek = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const displayLessonPlan = (state, action) => {
  return updateObject(state, {
    loading: false,
    lessonPlan: action.lessonPlan,
    week: action.week,
    error: null,
  });
};

const hideSubject = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const subjectClassPriorityReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBJECT_CLASS_PRIORITY_START:
      return subjectClassPriorityStart(state, action);
    case SUBJECT_CLASS_PRIORITY_VIEW:
      return subjectClassPriorityView(state, action);
    case SUBJECT_CLASS_PRIORITY_DELETE:
      return subjectClassPriorityDelete(state, action);
    case SUBJECT_CLASS_PRIORITY_UPDATE:
      return subjectClassPriorityUpdate(state, action);
    case SUBJECT_CLASS_PRIORITY_FAIL:
      return subjectClassPriorityFail(state, action);
    case SUBJECT_CLASS_PRIORITY_GET_WEEK:
      return getWeek(state, action);
    case SUBJECT_CLASS_PRIORITY_DISPLAY_LESSON:
      return displayLessonPlan(state, action);
    case SUBJECT_CLASS_PRIORITY_HIDE:
      return hideSubject(state, action);
    case SUBJECT_CLASS_PRIORITY_RESET:
      return resetState(state, action);

    default:
      return state;
  }
};

export default subjectClassPriorityReducer;
