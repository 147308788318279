import React from "react";

import { Route, Redirect } from "react-router-dom";

export const ProtectedRoute = ({ component: Component, ...arg }) => {
	return (
		<Route
			{...arg}
			path={arg.path}
			render={(props) => {
				if (arg.auth && arg.isAdmin) {
					return <Component {...props} />;
				} else {
					return <Redirect to="/login" />;
				}
				// console.log(props);
			}}
		/>
	);
};
