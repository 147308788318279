import { updateObject } from "../../shared/utility";

import {
  LESSONPLAN_ADD,
  LESSONPLAN_DELETE,
  LESSONPLAN_VIEW,
  LESSONPLAN_UPDATE,
  LESSONPLAN_FAIL,
  LESSONPLAN_START,
  LESSONPLAN_RESET,
  LESSONPLAN_UPLOAD_PLUSTWO,
  LESSONPLAN_UPLOAD,
} from "../actions/actionTypes";

const initialState = {
  LessonPlan: [],
  msg: null,
  error: null,
  loading: false,
};

const LessonPlanReset = (state, action) => {
  return updateObject(state, {
    LessonPlan: [],
    msg: null,
    error: null,
  });
};

const LessonPlanAdd = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const LessonPlanStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const LessonPlanFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    msg: null,
  });
};
const LessonPlanView = (state, action) => {
  return updateObject(state, {
    loading: false,
    LessonPlan: action.LessonPlan,
  });
};

const LessonPlanDelete = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const LessonPlanUpdate = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const handleLessonPlanUpload = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const handleLessonPlanUploadPlusTwo = (state, action) => {
  return updateObject(state, {
    loading: false,
    msg: action.msg,
    error: null,
  });
};

const LessonPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case LESSONPLAN_RESET:
      return LessonPlanReset(state, action);
    case LESSONPLAN_START:
      return LessonPlanStart(state, action);
    case LESSONPLAN_ADD:
      return LessonPlanAdd(state, action);
    case LESSONPLAN_VIEW:
      return LessonPlanView(state, action);
    case LESSONPLAN_DELETE:
      return LessonPlanDelete(state, action);
    case LESSONPLAN_UPDATE:
      return LessonPlanUpdate(state, action);
    case LESSONPLAN_FAIL:
      return LessonPlanFail(state, action);
    case LESSONPLAN_UPLOAD:
      return handleLessonPlanUpload(state, action);
    case LESSONPLAN_UPLOAD_PLUSTWO:
      return handleLessonPlanUploadPlusTwo(state, action);
    default:
      return state;
  }
};

export default LessonPlanReducer;
