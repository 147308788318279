import axios from "axios";

import {
	SUBJECT_ADD,
	SUBJECT_VIEW,
	SUBJECT_DELETE,
	SUBJECT_UPDATE,
	SUBJECT_START,
	SUBJECT_FAIL,
	SUBJECT_RESET,
	SUBJECT_SEARCH,
} from "./actionTypes";

export const subjectCreate = (msg) => {
	return {
		type: SUBJECT_ADD,
		msg: msg,
	};
};
export const handlesubjectReset = () => {
	return {
		type: SUBJECT_RESET,
	};
};

export const subjectStart = () => {
	return {
		type: SUBJECT_START,
	};
};

export const subjectFail = (msg) => {
	return {
		type: SUBJECT_FAIL,
		error: msg,
	};
};

export const subject = (subject) => {
	return {
		type: SUBJECT_VIEW,
		subject: subject,
	};
};

export const subjectDestroy = (msg) => {
	return {
		type: SUBJECT_DELETE,
		msg: msg,
	};
};

export const subjectPatch = (msg) => {
	return {
		type: SUBJECT_UPDATE,
		msg: msg,
	};
};

export const subjectReset = () => {
	return (dispatch) => {
		dispatch(handlesubjectReset());
	};
};

export const handleSubjectSearch = (subject) => {
	return {
		type: SUBJECT_SEARCH,
		subject: subject,
	};
};

export const subjectAdd = (subject) => {
	console.log("Hello I'm from subjectA.js");
	console.log(subject);

	return async (dispatch) => {
		// dispatch(subjectStart());
		await axios
			.post("/subject/store", {
				Subject: subject,
			})
			.then((response) => {
				response.data?.response?.message
					? dispatch(subjectCreate(response.data.response?.message))
					: response.data?.response
					? dispatch(subjectCreate(response.data.response))
					: dispatch(subjectCreate("Network is not Established"));

				if (response.data.isSuccess == false) {
					dispatch(subjectFail(response.data.response?.message));
				}
			})
			.catch((err) => {
				console.log(err);
				err.data?.message
					? dispatch(subjectFail(err.data?.message))
					: err.data?.response
					? dispatch(subjectFail(err.data?.response))
					: dispatch(subjectFail("Network is not Established"));
			});
		dispatch(subjectView());
	};
};

export const subjectDelete = (subject) => {
	return async (dispatch) => {
		// dispatch(subjectStart());
		await axios
			.post("subject/" + subject, { _method: "DELETE" })
			.then((response) => {
				response.data?.response?.message
					? dispatch(subjectDestroy(response.data?.response?.message))
					: response.data?.response
					? dispatch(subjectDestroy(response.data?.response))
					: dispatch(subjectDestroy("Something went wrong! Please try again."));
			})
			.catch((err) => {
				console.log(err);
				err.data?.message
					? dispatch(subjectFail(err.data?.message))
					: err.data?.response
					? dispatch(subjectFail(err.data?.response))
					: dispatch(subjectFail("Network is not Established"));
			});
		dispatch(subjectView());
	};
};

export const subjectUpdate = (id, subject) => {
	return async (dispatch) => {
		// dispatch(subjectStart());
		await axios
			.post("subject/" + id, {
				Subject: subject,
				_method: "PUT",
			})
			.then((response) => {
				response.data?.response?.message
					? dispatch(subjectPatch(response.data.response?.message))
					: response.data?.response
					? dispatch(subjectPatch(response.data?.response))
					: dispatch(subjectPatch("Something went wrong! Please try again."));
			})
			.catch((err) => {
				console.log(err);
				err.data?.message
					? dispatch(subjectFail(err.data?.message))
					: err.data?.response
					? dispatch(subjectFail(err.data?.response))
					: dispatch(subjectFail("Network is not Established"));
			});
		dispatch(subjectView());
	};
};

export const subjectView = (url = "subject/details") => {
	return async (dispatch) => {
		dispatch(subjectStart());
		await axios
			.get(url)
			.then((response) => {
				console.log(response);
				response.data?.response
					? dispatch(subject(response.data?.response))
					: dispatch(subject("Network is not Established"));
			})
			.catch((err) => {
				err.data?.message
					? dispatch(subjectFail(err.data?.message))
					: dispatch(subjectFail("Network is not Established"));
			});
	};
};

export const subjectSearch = (query) => {
	return async (dispatch) => {
		dispatch(subjectStart());
		await axios
			.post("subject/search", { search: query })
			.then((response) => {
				console.log(response);
				response.data?.response
					? dispatch(handleSubjectSearch(response.data?.response))
					: dispatch(handleSubjectSearch("Network is not Established"));
			})
			.catch((err) => {
				err.data?.message
					? dispatch(subjectFail(err.data?.message))
					: dispatch(subjectFail("Network is not Established"));
			});
	};
};
