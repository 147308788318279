import { Button, Modal } from "react-bootstrap";

const SubjectPriorityEditModal = (props) => {
  console.log(props.data);
  console.log(props.state);
  console.log("props.id");

  const clickHandler = () => {
    props.onEditRef(
      props.id,
      props.state.grade_id,
      props.state.subject_id,
      props.state.user_id,
      props.state.priority
    );
    props.onHide();
  };
  console.log(props.state);

  const priorityHandler = (e) => {
    e.preventDefault();
    props.setStateRef((prev) => {
      return { ...prev, priority: e.target.value };
    });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label for="priority">Priority:</label>
        <select
          className="form-control"
          id="priority"
          onChange={priorityHandler}
        >
          <option selected>Set Priority</option>
          {[...Array(15)].map((_, index) => (
            <option
              value={index + 1}
              key={(index + 1).toString()}
              selected={props.state.priority == index + 1}
            >
              {index + 1}
            </option>
          ))}
        </select>
        {/* <input
          type="number"
          name="priority"
          className="form-control"
          min="1"
          onChange={priorityHandler}
        /> */}
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "flex-start" }}>
        <Button onClick={clickHandler} className="btn-info">
          Edit
        </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SubjectPriorityEditModal;
