import React, { useEffect, useState } from "react";
import { Container, Row, Table, Button } from "react-bootstrap";

import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import Spinner from "../../../../components/spinner/Spinner";
import {
	gradeView,
	subjectClassPriorityView,
	subjectClassPriorityUpdate,
	SubjectClassResetState,
	gradeReset,
} from "../../../../store/actions";

import SubjectPriorityEditModal from "../../../../components/Modal/SubjectPriority/SubjectPriorityEditModal";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const SubjectPriority = (props) => {
	const [grade, setgrade] = useState({ value: null, show: false });

	const [subjectPriority, setsubjectPriority] = useState({
		grade_id: null,
		subject_id: null,
		user_id: null,
		priority: null,
	});

	const [modalEditShow, setEditModalShow] = useState({
		show: false,
		id: null,
	});

	const editRef = props.onSubjectPriorityEdit;

	useEffect(() => {
		return (
			props.onGradeView(),
			props.onSubjectClassResetState(),
			props.ongradeReset()
		);
	}, []);

	if (props.loading || props.gradeLoading) {
		return <Spinner />;
	}

	if (props.error) {
		toast(props.error, {
			position: "top-center",
			type: "error",
			toastId: "custom-id",
		});
	}
	if (props.msg) {
		toast(props.msg, {
			position: "top-center",
			type: "error",
			toastId: "custom-id",
		});
	}

	const viewHandler = (event) => {
		console.log("viewHandler");
		console.log(event.target.value);
		event.preventDefault();
		setgrade({
			value: event.target.value,
			show: true,
		});
		props.onSubjectPriorityShow(event.target.value);
		props.onSubjectClassResetState();
	};
	return (
		<>
			<Container>
				<Row>
					<form className="form-inline col-md-4">
						<div className="form-group">
							<select className="form-control" onChange={viewHandler}>
								<option selected>Select Grade</option>
								{props.grade.map((value) => {
									return (
										<option
											value={value.Grade}
											key={value.id}
											selected={grade.value == value.Grade}
										>
											{value.Grade}
										</option>
									);
								})}
							</select>
						</div>
					</form>

					<Table
						responsive
						striped
						bordered
						hover
						style={{ textAlign: "center" }}
					>
						<thead>
							<tr>
								<th>Teacher</th>
								<th>Subject</th>
								<th>Grade</th>
								<th>Priority</th>
								<th>Actions</th>
							</tr>
						</thead>
						{grade.show == true && props.priority.length != null ? (
							<tbody>
								{props.priority.map((value, i) => {
									return (
										<tr key={value.id}>
											<td>{value.user.FirstName}</td>
											<td>{value.subject.Subject}</td>
											<td>{value.grade.Grade}</td>
											<td>{value.Priority}</td>
											<td>
												<FontAwesomeIcon
													icon={faEdit}
													style={{ margin: "0px 5px" }}
													onClick={() => {
														setEditModalShow({
															show: true,
															id: value.id,
														});
														setsubjectPriority({
															grade_id: value.grade_id,
															subject_id: value.subject_id,
															user_id: value.user.id,
															priority: value.Priority,
														});
													}}
												/>
											</td>
										</tr>
									);
								})}
							</tbody>
						) : (
							<tbody>
								<tr>
									<td colSpan="6" className="text-center">
										Please Select Grade
									</td>
								</tr>
							</tbody>
						)}
					</Table>

					{modalEditShow.show ? (
						<SubjectPriorityEditModal
							show={modalEditShow.show}
							id={modalEditShow.id}
							state={subjectPriority}
							setStateRef={setsubjectPriority}
							grade_id={grade.value}
							name="Subject Priority"
							onEditRef={editRef}
							onHide={() =>
								setEditModalShow({ show: false, id: null, value: null })
							}
						/>
					) : null}
				</Row>
			</Container>
			<ToastContainer />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		grade: state.gradeR.grade,
		gradeLoading: state.gradeR.loading,
		priority: state.subjectClassPriorityR.subjectClassPriority,
		msg: state.subjectClassPriorityR.msg,
		error: state.subjectClassPriorityR.error,
		loading: state.subjectClassPriorityR.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		ongradeReset: () => dispatch(gradeReset()),

		onSubjectClassResetState: () => dispatch(SubjectClassResetState()),

		onGradeView: () => dispatch(gradeView()),

		onSubjectPriorityShow: (id) => dispatch(subjectClassPriorityView(id)),

		onSubjectPriorityEdit: (id, grade_id, subject_id, user_id, priority) =>
			dispatch(
				subjectClassPriorityUpdate(id, grade_id, subject_id, user_id, priority)
			),

		// onSubjectPriorityDelete: (id, subjectClassPriority) =>
		//   dispatch(subjectClassPriorityDelete(id, subjectClassPriority)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SubjectPriority);
