import { Button, Modal } from "react-bootstrap";

const TeacherEditModal = (props) => {
  console.log(props.data);
  console.log("props.id");

  const clickHandler = () => {
    props.onEditRef(
      props.id,
      props.state.user_id,
      props.state.subject_id,
      props.grade_id
    );
    props.onHide();
  };

  const subjectHandler = (e) => {
    e.preventDefault();
    props.setStateRef((prev) => {
      console.log(prev);
      return { ...prev, subject_id: e.target.value };
    });
  };

  const userHandler = (e) => {
    e.preventDefault();
    props.setStateRef((prev) => {
      console.log(prev);
      return { ...prev, user_id: e.target.value };
    });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label for="user">User:</label>
        <select className="form-control" id="user" onChange={userHandler}>
          <option selected>Select User</option>
          {props?.data?.teacher.map((value) => {
            return (
              <option
                value={value.id}
                key={value.id}
                selected={props.state.user_id == value.id}
              >
                {value.FirstName + " " + value.LastName}
              </option>
            );
          })}
        </select>
        <br />

        <label for="subject">Subject:</label>
        <select className="form-control" id="subject" onChange={subjectHandler}>
          <option selected>Select Subject</option>
          {props.data.subject.map((value) => {
            return (
              <option
                value={value.id}
                key={value.id}
                selected={props.state.subject_id == value.id}
              >
                {value.Subject}
              </option>
            );
          })}
        </select>

        {/* <br />

        <label for="role">Grade:</label>
        <select className="form-control" id="role" onChange={selectHandler}>
          <option value="1" selected={props.state.role == "admin" ? 1 : 0}>
            Admin
          </option>
          <option value="2" selected={props.state.role == "teacher" ? 1 : 0}>
            Teacher
          </option>
        </select> */}
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "flex-start" }}>
        <Button onClick={clickHandler} className="btn-info">
          Edit
        </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TeacherEditModal;
