import { combineReducers } from "redux";

import gradeReducer from "./gradeR";
import authReducer from "./authR";
import subjectReducer from "./subjectR";
import termReducer from "./termR";
import LessonPlanReducer from "./lessonPlanR";
import userReducer from "./userR";
import teacherReducer from "./teacherR";
import subjectClassPriorityReducer from "./subjectClassPriorityR";

const allReducers = combineReducers({
	authR: authReducer,
	gradeR: gradeReducer,
	subjectR: subjectReducer,
	termR: termReducer,
	lessonPlanR: LessonPlanReducer,
	userR: userReducer,
	teacherR: teacherReducer,
	subjectClassPriorityR: subjectClassPriorityReducer,
});

export default allReducers;
