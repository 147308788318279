import { Button, Modal } from "react-bootstrap";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";

const TermAddModal = (props) => {
  console.log(props);
  const clickHandler = () => {
    props.onAddRef(
      props.state.term,
      props.state.startDate,
      props.state.endDate
    );
    props.onHide();

    console.log(props.state);
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton onClick={props.onHide}>
        <Modal.Title id="contained-modal-title-vcenter">
          Add {props.name}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <label for="term">Term:</label>
        <input
          type="text"
          placeholder="Enter term name"
          className="form-control"
          onChange={(e) => {
            e.preventDefault();
            props.setStateRef((prev) => {
              console.log(prev);
              return { ...prev, term: e.target.value };
            });
          }}
        />
        <br />

        <label for="startDate">Start Date:</label>
        <NepaliDatePicker
          placeholder="Choose a date"
          inputClassName="form-control"
          // value={props.state.startDate}
          onChange={(e) => {
            // e.preventDefault();
            console.log(e);
            props.setStateRef((prev) => {
              return { ...prev, startDate: e };
            });
          }}
          //   options={{ calenderLocale: "ne", valueLocale: "en" }} //Nepali format view
          options={{ calenderLocale: "en", valueLocale: "en" }}
        />
        <br />
        <label for="endDate">End Date:</label>
        <NepaliDatePicker
          inputClassName="form-control"
          onChange={(e) => {
            // e.preventDefault();
            props.setStateRef((prev) => {
              return { ...prev, endDate: e };
            });
          }}
          options={{ calenderLocale: "en", valueLocale: "en" }}
        />
      </Modal.Body>

      <Modal.Footer style={{ justifyContent: "flex-start" }}>
        <Button variant="primary" onClick={clickHandler}>
          Add
        </Button>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TermAddModal;
