import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";
import Classes from "./subject.module.css";
import NepaliDate from "nepali-date-converter";
import { Redirect } from "react-router-dom";
import { getWeek, subjectClassPriorityView } from "../../../store/actions";
import { connect } from "react-redux";
import Spinner from "../../../components/spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const Subject = (props) => {
	const [subject, setsubject] = useState("");
	const [loading, setloading] = useState(false);
	const [selectedSubject, setSelectedSubject] = useState(null);
	const [error, setError] = useState(" ");
	const grade = props.match.params.grade;

	let data;
	const fetchData = async () => {
		setloading(true);

		try {
			data = await axios
				.get(`/viewSubjectClassPriority/details/${grade}`)
				.catch((error) => {
					setError(error.data?.response);
					console.log(error);
					setloading(false);
				});
			setsubject(data.data?.response);
			setloading(false);
		} catch (e) {}
	};

	useEffect(() => fetchData(), []);

	if (loading) {
		return <Spinner />;
	}

	if (error !== " ") {
		toast(error, {
			position: "top-center",
			type: "error",
			toastId: "custom-id",
		});
	}

	const submitHandler = (e, grade_id, subject_id) => {
		e.preventDefault();
		setSelectedSubject(subject_id);
		let NepTodayDate = new NepaliDate();
		let EngTodayDate = new Date();
		let day = NepTodayDate.getDay();

		if (day == 0 || day == 6) {
			day == 0
				? EngTodayDate.setDate(EngTodayDate.getDate() + 1)
				: EngTodayDate.setDate(EngTodayDate.getDate() + 2);
		} else {
			let diff = day - 1;

			EngTodayDate.setDate(EngTodayDate.getDate() - diff);
		}
		let mondayDate = new NepaliDate(EngTodayDate);
		console.log("mondayDate is", mondayDate);

		props.onGetWeek(mondayDate.format("YYYY-MM-DD"), grade_id, subject_id);
	};

	if (props.loading) {
		return <Spinner />;
	}

	if (
		props.lessonPlan.length !== 0 &&
		props?.lessonPlan?.first?.subject_class_priority?.subject_id ===
			selectedSubject
	) {
		const link = `/ViewLessonPlan/${props.lessonPlan.first.subjectclass_id}/${props.lessonPlan.first.term_id}/${props.week}`;
		return <Redirect to={link} />;
	}

	if (props.msg) {
		return <Redirect to="/updated-soon" />;
	}
	console.log(props);
	return (
		<>
			<Container fluid id={Classes.subjectSection}>
				<Row>
					{[...subject]?.map((val) => {
						return (
							<Col md={4} key={val.id}>
								<form
									className={Classes.viewLessonPlan}
									onSubmit={(e) =>
										submitHandler(e, val.grade.id, val.subject.id)
									}
								>
									<button className={Classes.card} type="submit" id={val.id}>
										<div>
											<span className={Classes.whiteText}>
												<h2 className={Classes.text}>
													<FontAwesomeIcon
														icon={faBookOpen}
														style={{ margin: "0px 8px" }}
													/>
													{val.subject.Subject}
												</h2>
												{/* Subject {i?.Grade} */}
											</span>
										</div>
									</button>
								</form>
							</Col>
						);
					})}
				</Row>
			</Container>
			<ToastContainer />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		lessonPlan: state.subjectClassPriorityR.lessonPlan,
		msg: state.subjectClassPriorityR.msg,
		error: state.subjectClassPriorityR.error,
		loading: state.subjectClassPriorityR.loading,
		week: state.subjectClassPriorityR.week,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		onSubjectPriorityShow: (id) => dispatch(subjectClassPriorityView(id)),
		onGetWeek: (date, grade_id, subject_id) => {
			dispatch(getWeek(date, grade_id, subject_id));
		},
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Subject);
