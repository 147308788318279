import { Button, Modal } from "react-bootstrap";

const SubjectHideModal = (props) => {
  const clickHandler = () => {
    props.onHideRef(props.state.id, props.state.hide, props.state.grade);
    props.onHide();
    console.log(props.state);
  };
  console.log(props.state);

  let name = null;

  name = props.state.hide === "1" ? "Hide" : "Show";
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton onClick={props.onHide}>
        <Modal.Title id="contained-modal-title-vcenter">
          {name} Subject
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h5>Are you sure you want to {name} this subject?</h5>
      </Modal.Body>

      <Modal.Footer style={{ justifyContent: "flex-start" }}>
        <Button variant="primary" onClick={clickHandler}>
          {name}
        </Button>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SubjectHideModal;
