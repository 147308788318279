import React from "react";
import { Row, Col, Container } from "react-bootstrap";

const UpdatedSoon = (props) => {
  return (
    <Container fluid>
      <Row className="justify-content-md-center">
        <Col className="text-center">
          <br />
          <br />
          <h1>
            Site will be <br />
            updated soon!
          </h1>
          <br />
          <br />
        </Col>
      </Row>
    </Container>
  );
};

export default UpdatedSoon;
